import { isProd } from '@/utils/helpers';
import { zo } from 'husky-shared-fe-components';

const loginSchema = zo.object({
  email: zo.string().min(1).email(),
  // TODO: Someday change our environment test accounts to accept only passwords longer than 8 chars
  password: isProd ? zo.string().min(1).min(8).max(128) : zo.string().min(1).max(128),
});

const resetPasswordTokenSchema = zo.object({
  email: zo.string().min(1).email(),
});

const changePasswordSchema = zo
  .object({
    password: zo.string().min(1).min(8).max(128),
    confirmPassword: zo.string().min(1).min(8).max(128),
  })
  .refine((data) => data.password === data.confirmPassword, {
    params: { i18n: 'passwordMismatch' },
    path: ['confirmPassword'],
  });

type ResetPasswordTokenSchemaType = zo.infer<typeof resetPasswordTokenSchema>;
type LoginSchemaType = zo.infer<typeof loginSchema>;
type ChangePasswordSchemaType = zo.infer<typeof changePasswordSchema>;

export { loginSchema, resetPasswordTokenSchema, changePasswordSchema };
export type { LoginSchemaType, ResetPasswordTokenSchemaType, ChangePasswordSchemaType };
