import { ErrorFallback } from 'husky-shared-fe-components';
import { Outlet, useLocation } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { ErrorBoundary } from 'react-error-boundary';
import { useUIAdapterLocale } from 'api/queries/ui';
import { Toaster } from 'react-hot-toast';
import SetupGoogleMaps from 'setup/SetupGoogleMaps';
import SetupTranslations from 'setup/SetupTranslations';
import SetupCookies from 'setup/SetupCookies';
import { ROUTES } from '@/utils/routes';
import { isProd } from '@/utils/helpers';

function Setup() {
  const { adapterLocale } = useUIAdapterLocale();
  const location = useLocation();
  const isCookiePage = location.pathname === ROUTES.BaseRoutes.COOKIES;

  const isCookieBannerEnabled = isProd && !isCookiePage;
  return (
    <ErrorBoundary fallback={<ErrorFallback />}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={adapterLocale}>
        {isCookieBannerEnabled && <SetupCookies />}
        <Toaster />
        <SetupGoogleMaps />
        <SetupTranslations />
        <Outlet />
      </LocalizationProvider>
    </ErrorBoundary>
  );
}

export default Setup;
