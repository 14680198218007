import { useCallback, useEffect, useState } from 'react';
import { CookieKeyEnum } from 'utils/enums';
import Cookies from 'js-cookie';
import { clearCookies } from 'utils/cookie-helpers';
import { useQueryClient } from '@tanstack/react-query';
import { useAuthRefreshMutation } from 'api/queries/auth';

export const useResetAppState = () => {
  const queryClient = useQueryClient();

  const resetState = useCallback(() => {
    queryClient.removeQueries();
    clearCookies();
  }, [queryClient]);

  return resetState;
};

/*
 * Handles checking for an authentication status for a customer
 */
function SessionProvider({ children }: { children: JSX.Element }) {
  const [isFetched, setIsFetched] = useState(false);
  const resetState = useResetAppState();
  const { mutateAsync } = useAuthRefreshMutation();

  // To determine if the previous session is valid we try to refresh the token
  // If the request succeeds, the previous session is valid and the customer is able to proceed
  // Otherwise we redirect the user to authenticate again via resetState
  useEffect(() => {
    (async () => {
      try {
        const refreshToken = Cookies.get(CookieKeyEnum.REFRESH_TOKEN_BUSINESS);
        if (refreshToken == null) return;

        const response = await mutateAsync();
        if (response == null) resetState();
        // User will get navigated automatically
      } catch (err) {
        resetState();
      } finally {
        setIsFetched(true);
      }
    })();
  }, [resetState, mutateAsync]);

  if (!isFetched) return null;

  return children;
}

export default SessionProvider;
