import {
  DriverAccommodationType,
  PaymentMethod,
  RideOfferType,
  clientDtoSchema,
  contactInfoDtoSchema,
  groupLeadSchema,
  legSchema,
  zo,
  specialLuggageObjectSchema,
  passengerCountWithMaxSchemaObject,
  driverAccommodationTypeObjectSchema,
  orderFilesSchemaObject,
  driverPublicDtoSchema,
  vehiclePublicDtoSchema,
  billingEntityDtoSchema,
} from 'husky-shared-fe-components';

const offerClientDataSchema = zo
  .object({
    billingEntity: billingEntityDtoSchema,
    client: clientDtoSchema.optional(),
    clientIsGroupLead: zo.boolean(),
    driverAccommodationType: zo.nativeEnum(DriverAccommodationType).optional(),
    groupLead: groupLeadSchema.shape.groupLead.optional(),
    pickUpLocationMessage: zo.string().optional(),
  })
  .partial();

type OfferClientDataSchemaType = zo.infer<typeof offerClientDataSchema>;

const ridePublicDtoSchema = zo.object({
  driver: driverPublicDtoSchema.optional(),
  legs: zo.array(legSchema),
  priceExclVat: zo.number(),
  priceInclVat: zo.number(),
  operatorPriceExclVat: zo.number().optional(),
  operatorPriceInclVat: zo.number().optional(),
  rideNumber: zo.number(),
  vatPct: zo.number(),
  vehicle: vehiclePublicDtoSchema,
});
type RidePublicDtoSchemaType = zo.infer<typeof ridePublicDtoSchema>;
type DriverPublicDtoSchemaType = zo.infer<typeof driverPublicDtoSchema>;

const orderPublicDtoSchema = zo.object({
  affiliateCompanyId: zo.number().optional(),
  affiliateCompanyName: zo.string().optional(),
  bookingNumber: zo.string(),
  client: clientDtoSchema.optional(),
  currency: zo.string(),
  driverAccommodationType: zo.nativeEnum(DriverAccommodationType),
  groupLead: groupLeadSchema.optional(),
  operatorEmail: zo.string().optional(),
  operatorId: zo.number(),
  operatorLogoUrl: zo.string().optional(),
  operatorName: zo.string(),
  operatorPhoneNumber: zo.string().optional(),
  operatorPriceExclVat: zo.number(),
  operatorPriceInclVat: zo.number(),
  orderedByContactInfo: contactInfoDtoSchema.optional(),
  overnight: zo.boolean(),
  passengerCount: zo.number(),
  paymentMethod: zo.nativeEnum(PaymentMethod),
  pickUpLocationMessage: zo.string().optional(),
  price: zo.number(),
  priceExclVat: zo.number(),
  priceInclVat: zo.number(),
  rideDetailsFileUrl: zo.string().optional(),
  rides: zo.array(ridePublicDtoSchema),
  signFileUrl: zo.string().optional(),
  signText: zo.string().optional(),
  specialLuggageCount: zo.number(),
  specialLuggageType: zo.number(),
  type: zo.nativeEnum(RideOfferType),
  unadjustedPrice: zo.number(),
  unadjustedPriceExclVat: zo.number(),
  unadjustedPriceInclVat: zo.number(),
  clientListPriceInclVat: zo.number(),
  clientListPriceExclVat: zo.number(),
  vatPct: zo.number(),
});
type OrderPublicDtoSchemaType = zo.infer<typeof orderPublicDtoSchema>;

const generalEditOrderSchema = passengerCountWithMaxSchemaObject
  .and(driverAccommodationTypeObjectSchema)
  .and(orderFilesSchemaObject)
  .and(
    zo.object({
      rides: zo.array(ridePublicDtoSchema),
      pickUpLocationMessage: zo.string().min(1).max(256).optional().or(zo.literal('')),
      signText: zo.string().min(1).max(256).optional().or(zo.literal('')),
    })
  );

const groupLeadLuggageEditOrderSchema = groupLeadSchema.and(specialLuggageObjectSchema);

const editOrderCombinedSchema = generalEditOrderSchema.and(groupLeadLuggageEditOrderSchema);

type GeneralEditOrderSchemaType = zo.infer<typeof generalEditOrderSchema>;
type GroupLeadLuggageEditOrderSchemaType = zo.infer<typeof groupLeadLuggageEditOrderSchema>;
type EditOrderCombinedSchemaType = zo.infer<typeof editOrderCombinedSchema>;

export { generalEditOrderSchema, groupLeadLuggageEditOrderSchema, editOrderCombinedSchema };
export type {
  OfferClientDataSchemaType,
  OrderPublicDtoSchemaType,
  RidePublicDtoSchemaType,
  GeneralEditOrderSchemaType,
  GroupLeadLuggageEditOrderSchemaType,
  EditOrderCombinedSchemaType,
  DriverPublicDtoSchemaType,
};
