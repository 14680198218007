import { Box } from '@mui/material';
import BannerSectionOperator from 'components/landing/BannerSectionOperator';
import { useTranslation } from 'react-i18next';
import AppMenu from 'components/AppMenu';
import Footer from 'components/Footer';
import HeroSection from 'components/landing/HeroSection';
import FAQSection from 'components/landing/FAQSection';
import ContactUsSection from 'components/landing/ContactUsSection';

function BecomeOperatorView() {
  const { t } = useTranslation();
  return (
    <>
      <AppMenu />
      <Box display='flex' flexDirection='column' alignItems='center'>
        <HeroSection />
        <BannerSectionOperator text={t('BecomeOperatorView.banner.title')} />
        <FAQSection
          title={t('FAQView.title')}
          subtitle={t('FAQView.lists.operator.subtitle').toString()}
          FAQListKey='FAQView.lists.operator.list'
        />
        <ContactUsSection />
      </Box>
      <Footer />
    </>
  );
}

export default BecomeOperatorView;
