import {
  CheckboxInputField,
  ControlledField,
  PhoneInputField,
  SelectInputField,
  SpecialLuggageType,
} from 'husky-shared-fe-components';

import { useTranslation } from 'react-i18next';
import { Grid, Box } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import EditOrderSectionTitle from 'components/editOrder/EditOrderSectionTitle';
import { EditOrderCombinedSchemaType } from '../types';

const specialLuggageOptions = Object.values(SpecialLuggageType);

function GroupLeadLuggageOrderForm({
  formInstance,
}: {
  formInstance: UseFormReturn<EditOrderCombinedSchemaType>;
}) {
  const tInstance = useTranslation();
  const { t } = tInstance;
  const { control, watch, setValue } = formInstance;
  const isSpecialLuggage = watch('specialLuggage') === true;
  const specialLuggageCount = watch('specialLuggageCount');
  const specialLuggageType = watch('specialLuggageType');

  return (
    <Grid container columnSpacing={10} justifyContent='center'>
      <Grid item xs={12} md={6}>
        <Box id='groupLeadSection'>
          <EditOrderSectionTitle
            title={t('ChangeOrderDetailsView.steps.extraDetails.groupLead.title').toString()}
          />
          <ControlledField
            control={control}
            name='groupLead.firstName'
            translationKey='firstName'
            fieldProps={{ InputProps: { autoComplete: 'given-name' } }}
          />
          <ControlledField
            control={control}
            name='groupLead.lastName'
            translationKey='lastName'
            fieldProps={{ InputProps: { autoComplete: 'family-name' } }}
          />
          <ControlledField
            control={control}
            name='groupLead.email'
            translationKey='email'
            fieldProps={{
              InputProps: {
                type: 'email',
                autoComplete: 'email',
              },
            }}
          />
          <ControlledField
            control={control}
            name='groupLead.phone'
            render={(renderProps) => <PhoneInputField {...renderProps} translationKey='phone' />}
          />
        </Box>
        <Box id='specialLuggageSection'>
          <EditOrderSectionTitle
            title={t('ChangeOrderDetailsView.steps.extraDetails.specialLuggage.title').toString()}
          />
          <Grid container item>
            <Grid item xs={12} md={6} mt='18px'>
              <ControlledField
                control={control}
                name='specialLuggage'
                render={(renderProps) => (
                  <CheckboxInputField
                    {...renderProps}
                    fieldProps={{
                      onChange: (_, value) => {
                        setValue('specialLuggage', value);
                        if (!value) {
                          setValue('specialLuggageCount', null);
                          setValue('specialLuggageType', null);
                        }
                      },
                    }}
                  />
                )}
              />
            </Grid>
            {isSpecialLuggage && (
              <>
                <Grid item xs={12} md={6}>
                  <ControlledField
                    control={control}
                    name='specialLuggageCount'
                    fieldProps={{
                      value: specialLuggageCount === null ? '' : specialLuggageCount,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ControlledField
                    control={control}
                    name='specialLuggageType'
                    defaultValue=''
                    render={(renderProps) => (
                      <SelectInputField
                        {...renderProps}
                        fieldProps={{
                          value: (specialLuggageType as any) || '',
                        }}
                        options={specialLuggageOptions.map((value) => ({
                          label: t(`SpecialLuggageType.${value}`),
                          value,
                        }))}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

export default GroupLeadLuggageOrderForm;
