import { colors } from 'husky-shared-fe-components';
import { CheckCircleRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Paper, Box, Typography } from '@mui/material';

function BarderPoleProcessing({ text }: { text?: string }) {
  const { t } = useTranslation();
  return (
    <>
      <Typography sx={{ fontSize: '1rem', mb: '14px' }}>
        {text ?? t('OffersView.pending.progress.title')}
      </Typography>
      <Paper
        elevation={1}
        sx={{
          width: '263px',
          mx: 'auto',
          fontSize: '2em',
          height: '28px',
          borderRadius: '1em',
          backgroundImage: `repeating-linear-gradient(100deg, ${colors.tiffanyBlue}, ${colors.tiffanyBlue} 25px, ${colors.secondary} 25px, ${colors.secondary} 50px)`,
          backgroundSize: '5000%',
          animation: 'animatedBackground 200s linear infinite',
          '@keyframes animatedBackground': {
            from: { backgroundPosition: '0 0' },
            to: { backgroundPosition: '-100% 0' },
          },
        }}
      />
    </>
  );
}
function BarderPoleDone({ text }: { text?: string }) {
  const { t } = useTranslation();
  return (
    <>
      <Typography
        sx={{
          fontSize: '1rem',
          mb: '14px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CheckCircleRounded />
        <span style={{ marginLeft: '6px' }}>{text ?? t('OffersView.pending.progress.done')}</span>
      </Typography>
      <Paper
        elevation={1}
        sx={{
          width: '263px',
          mx: 'auto',
          fontSize: '2em',
          height: '28px',
          borderRadius: '1em',
          backgroundColor: colors.tiffanyBlue,
        }}
      />
    </>
  );
}

interface Props {
  isDone?: boolean;
  text?: string;
}

function OffersLoading({ isDone, text }: Props) {
  return (
    <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
      {isDone ? <BarderPoleDone /> : <BarderPoleProcessing text={text} />}
    </Box>
  );
}

export default OffersLoading;
