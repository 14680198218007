import { Box } from '@mui/system';
import { Navigate, useParams } from 'react-router-dom';
import { PageLoader } from 'husky-shared-fe-components';
import { useAuthPasswordResetTokenStatusQuery } from 'api/queries/auth';
import { ROUTES } from 'utils/routes';
import { TokenStatus } from 'utils/enums';
import ChangePasswordForm from 'components/forms/AccountForm/ChangePasswordForm';
import AppMenu from 'components/AppMenu';
import Footer from 'components/Footer';
import CardContainer from 'components/CardContainer';

function ChangePasswordView() {
  const { id } = useParams();
  const { data: token, isSuccess, isError, isLoading } = useAuthPasswordResetTokenStatusQuery(id!);

  if (isLoading)
    return (
      <Box sx={{ display: 'flex', flexGrow: 1, minHeight: 'calc(100vh - 48px)' }}>
        <PageLoader />
      </Box>
    );

  const isStatusError = isSuccess && token.status !== TokenStatus.OK;
  if (isStatusError) {
    return <Navigate to={ROUTES.BaseRoutes.ROOT} replace />;
  }

  if (isError) {
    return <Navigate to={ROUTES.BaseRoutes.PASSWORD_RESET} replace />;
  }

  return (
    <>
      <AppMenu />
      <Box sx={{ minHeight: 'calc(100vh - 48px)' }}>
        <CardContainer>
          <ChangePasswordForm />
        </CardContainer>
      </Box>
      <Footer />
    </>
  );
}

export default ChangePasswordView;
