import { Grid } from '@mui/material';
import {
  PageLoader,
  RideOfferType,
  RideDetailsSchemaType,
  isB2bRole,
  CustomerOfferSchemaType,
  ConfirmModal,
} from 'husky-shared-fe-components';
import { useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { usePostOffersMutation } from 'api/queries/offers';
import { usePostClientsOffersMutation } from 'api/queries/clients';
import { OfferPayload } from 'utils/data-types';
import { captureException } from '@sentry/react';
import { useUIMapsGoogleLoaded, useUIOfferFormPreset } from 'api/queries/ui';
import { toast } from 'react-hot-toast';
import dayjs from 'dayjs';
import { useAuthClientJwtQuery } from 'api/queries/auth';
import { OFFER_COUNT_DURATION_IN_SECONDS } from 'components/OffersInquiry';
import VehicleOneWayRideStepForm from './steps/VehicleOneWayRideStepForm';
import VehicleRoundTripRideStepForm from './steps/VehicleRoundTripRideStepForm';
import VehicleMultiStopStepForm from './steps/VehicleMultiStopRideStepForm';

function isTimeAtMidnight(data: FieldValues): boolean {
  const selectedStartTime = dayjs(data.startTime).format('HH:mm');
  const selectedReturnTime = dayjs(data.returnTime).format('HH:mm');
  const legs = data.legs;
  const isLegsTimesMidnight = legs?.some((leg: any) => {
    const startTime = dayjs(leg.startTime).format('HH:mm');
    const endTime = dayjs(leg.endTime).format('HH:mm');
    return startTime === '00:00' || endTime === '00:00';
  });
  return selectedStartTime === '00:00' || selectedReturnTime === '00:00' || isLegsTimesMidnight;
}

function CreateRideOffersForm({
  defaultValues,
  isCounterRunning,
  handleInternalTestingCallback,
}: {
  defaultValues?: RideDetailsSchemaType;
  isCounterRunning?: boolean;
  handleInternalTestingCallback?: (offers: CustomerOfferSchemaType) => void;
}) {
  const { isMapsLoaded } = useUIMapsGoogleLoaded();
  const { data: userData } = useAuthClientJwtQuery();
  const { setOfferFormPreset } = useUIOfferFormPreset();
  const role = userData?.['husky.roles'];
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formResult, setFormResult] = useState<RideDetailsSchemaType | undefined>(defaultValues);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [areDepartureTimesConfirmed, setAreDepartureTimesConfirmed] = useState(true);
  const type = formResult?.type ?? RideOfferType.ROUND_TRIP;
  const isInternalTestingView = typeof handleInternalTestingCallback === 'function';
  const postOffersMutation = usePostOffersMutation();
  const postClientsOffersMutation = usePostClientsOffersMutation();

  const handleValidStep = async (data: FieldValues) => {
    if (isTimeAtMidnight(data) && !areDepartureTimesConfirmed) {
      setIsConfirmModalOpen(true);
      return;
    }

    const payload = {
      ...data,
      internalTesting: isInternalTestingView,
    } as OfferPayload;

    // Set the global state if it passes all validation, we don't care about if the request suceeds or not
    if (!isInternalTestingView) {
      setOfferFormPreset(payload as unknown as RideDetailsSchemaType);
    }

    try {
      // Use a different endpoint for logged in B2B users
      const response = isB2bRole(role ?? '')
        ? await postClientsOffersMutation.mutateAsync(payload)
        : await postOffersMutation.mutateAsync(payload);
      const isOffersAvailable = response.trips.flatMap((trip) => trip.operatorOffers).length > 0;

      // Start polling only when no offers available and 'waitingForOperatorOffers' is truthy
      const initiatePolling = !isOffersAvailable && response.waitingForOperatorOffers;

      if (response != null) {
        if (!isInternalTestingView) {
          // Redirect to offers view when it is not 'internal-testing' route
          navigate(`/offers/${response.bookingNumber}`, {
            replace: true,
            ...(initiatePolling && {
              state: {
                offersRequestedTimeEnd: dayjs()
                  .add(OFFER_COUNT_DURATION_IN_SECONDS, 'seconds')
                  .valueOf(),
              },
            }),
          });
          // Handle setting offers for 'internal-testing' route
        } else if (typeof handleInternalTestingCallback === 'function') {
          handleInternalTestingCallback(response as unknown as CustomerOfferSchemaType);

          // Show that there are no offers available for 'internal-testing'
          if (!isOffersAvailable) toast.error(t('Toast.noOffersFound'));
        }
      }
    } catch (err) {
      captureException(err);
    }
  };

  const handleDateTimeAccept = (val: FieldValues | null) => {
    if (!val) return;
    const timeString = dayjs(val as Date).format('HH:mm');
    if (timeString === '00:00') {
      setAreDepartureTimesConfirmed(false);
    }
  };

  if (!isMapsLoaded) return <PageLoader />;

  return (
    <>
      <ConfirmModal
        open={isConfirmModalOpen}
        setOpen={setIsConfirmModalOpen}
        onConfirm={() => {
          setIsConfirmModalOpen(false);
          setAreDepartureTimesConfirmed(true);
        }}
        onClose={() => {
          setIsConfirmModalOpen(false);
        }}
        title={t('modals.confirmTime.title').toString()}
        description={t('modals.confirmTime.description').toString()}
      />
      <Grid container item gap={2}>
        <Grid item xs={12}>
          {type === RideOfferType.ROUND_TRIP && (
            <VehicleRoundTripRideStepForm
              defaultValues={formResult}
              onValid={handleValidStep}
              setFormResult={setFormResult}
              isCounterRunning={isCounterRunning}
              handleDateTimeAccept={handleDateTimeAccept}
            />
          )}
          {type === RideOfferType.MULTI_STOP && (
            <VehicleMultiStopStepForm
              defaultValues={formResult}
              onValid={handleValidStep}
              setFormResult={setFormResult}
              isCounterRunning={isCounterRunning}
              handleDateTimeAccept={handleDateTimeAccept}
            />
          )}
          {type === RideOfferType.ONE_WAY && (
            <VehicleOneWayRideStepForm
              defaultValues={formResult}
              onValid={handleValidStep}
              setFormResult={setFormResult}
              isCounterRunning={isCounterRunning}
              handleDateTimeAccept={handleDateTimeAccept}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default CreateRideOffersForm;
