import { colors, ControlledField } from 'husky-shared-fe-components';
import { Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Stack } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';
import { useAuthPasswordResetTokenMutation } from 'api/queries/auth';
import { resetPasswordTokenSchema, ResetPasswordTokenSchemaType } from './types';

function ResetPasswordTokenForm() {
  const tInstance = useTranslation();
  const { t } = tInstance;
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, isValidating, isLoading },
  } = useForm<ResetPasswordTokenSchemaType>({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(resetPasswordTokenSchema),
  });
  const authPasswordResetTokenMutation = useAuthPasswordResetTokenMutation();
  const isSent = authPasswordResetTokenMutation.isSuccess;

  const onValid = (data: ResetPasswordTokenSchemaType) =>
    authPasswordResetTokenMutation.mutate(data);

  if (isSent) {
    const handleResetForm = () => {
      reset(); // resets form
      authPasswordResetTokenMutation.reset(); // resets mutation
    };
    return (
      <Stack alignItems='center' gap={1} maxWidth='350px'>
        <Typography mt='12px' mb='32px' variant='cardText'>
          {t('Toast.emailSent')}
        </Typography>
        <Button component={RouterLink} to='/log-in' sx={{ maxWidth: 'max-content' }}>
          {t('actions.fetch.goToLogin')}
        </Button>
        <Button type='button' variant='text' onClick={handleResetForm}>
          {t('actions.fetch.tryAgain')}
        </Button>
      </Stack>
    );
  }

  return (
    <form onSubmit={handleSubmit(onValid)} style={{ width: '100%', maxWidth: '350px' }}>
      <Typography sx={{ color: colors.primary, fontWeight: 600, fontSize: '30px' }}>
        {t('PasswordResetView.title')}
      </Typography>
      <Typography mt='12px' mb='32px' variant='cardText'>
        {t(`PasswordResetView.subtitle`)}
      </Typography>
      <Stack direction='column' spacing={1} maxWidth='400px' textAlign='left'>
        <ControlledField
          control={control}
          name='email'
          focusInitial
          fieldProps={{
            InputProps: {
              type: 'email',
              autoComplete: 'email',
            },
          }}
        />
      </Stack>
      <Button
        sx={{ marginTop: '24px', marginBottom: '16px' }}
        fullWidth
        type='submit'
        variant='contained'
        color='primary'
        disableElevation
        disabled={isSubmitting || isValidating || isLoading}
      >
        {t('submit')}
      </Button>
    </form>
  );
}

export default ResetPasswordTokenForm;
