import { useResetAppState } from '@/SessionProvider';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

function LogoutView() {
  const resetState = useResetAppState();

  useEffect(() => {
    resetState();
  }, [resetState]);

  return <Navigate to='/' replace />;
}

export default LogoutView;
