import { useQuery } from '@tanstack/react-query';
import { fetchAuth } from 'api/requests/requests';
import { STALE_FIVE_MINUTES } from 'api/types';
import Cookies from 'js-cookie';
import { CookieKeyEnum } from 'utils/enums';

export const usersQueryKeys = {
  all: ['users'] as const,
  currentAccesses: () => [...usersQueryKeys.all, 'current', 'accesses'] as const,
};

export function useUsersCurrentAccessesQuery() {
  const refreshToken = Cookies.get(CookieKeyEnum.REFRESH_TOKEN_BUSINESS);

  return useQuery({
    queryKey: usersQueryKeys.currentAccesses(),
    queryFn: fetchAuth,
    enabled: refreshToken != null,
    staleTime: STALE_FIVE_MINUTES,
    select: ({ data }) => data,
  });
}
