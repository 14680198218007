import { Box } from '@mui/system';
import ResetPasswordTokenForm from 'components/forms/AccountForm/ResetPasswordTokenForm';
import AppMenu from 'components/AppMenu';
import Footer from 'components/Footer';
import CardContainer from 'components/CardContainer';

function ResetPasswordTokenView() {
  return (
    <>
      <AppMenu />
      <Box sx={{ minHeight: 'calc(100vh - 48px)' }}>
        <CardContainer>
          <ResetPasswordTokenForm />
        </CardContainer>
      </Box>
      <Footer />
    </>
  );
}

export default ResetPasswordTokenView;
