/* eslint-disable import/prefer-default-export */
import { OrderPublicDtoSchemaType } from 'components/forms/types';
import { Offer, OffersV2, OperatorOfferSchemaType } from 'husky-shared-fe-components';

declare global {
  interface Window {
    gtag: any;
    fbq: any;
  }
}

export const isFBQInitialized = 'fbq' in window && typeof window?.fbq !== 'undefined';
export const isGTMInitialized = 'gtag' in window && typeof window?.gtag !== 'undefined';

const isGTMTrackingEnabled = (cb: Function) => {
  if (isGTMInitialized) cb();
};

const isFBQTrackingEnabled = (cb: Function) => {
  if (isFBQInitialized) cb();
};

const defaultTrackCurrency = 'EUR';
export const track = {
  product_impressions: (products: OffersV2) => {
    const offers = products.trips.flatMap((t) => t.operatorOffers);
    isGTMTrackingEnabled(() => {
      window.gtag({ ecommerce: null }); // Clear the previous ecommerce object
      const accumulatedTotalPrice = offers.reduce((total, item) => total + item.priceInclVat, 0);
      window.gtag('event', 'view_item_list', {
        ecommerce: {
          item_list_id: products.bookingNumber,
          currency: defaultTrackCurrency,
          value: accumulatedTotalPrice,
          items: offers.map((offer, index) => ({
            item_id: offer.uuid,
            currency: offer.currency,
            price: offer.priceInclVat,
            quantity: 1,
            index,
          })),
        },
      });
    });
    isFBQTrackingEnabled(() => {
      window.fbq('track', 'Search', {
        content_ids: offers.map(({ uuid }) => uuid),
        content_type: 'product',
        search_string: products.bookingNumber,
        contents: offers.map(({ uuid }) => ({ id: uuid, quantity: 1 })),
      });
    });
  },
  add_to_cart: (product: OperatorOfferSchemaType) => {
    isGTMTrackingEnabled(() => {
      window.gtag({ ecommerce: null }); // Clear the previous ecommerce object
      window.gtag('event', 'add_to_cart', {
        ecommerce: {
          currency: product.currency,
          value: product.priceInclVat,
          items: [
            {
              item_id: product.uuid,
              currency: product.currency,
              price: product.priceInclVat,
              quantity: 1,
              index: 0,
            },
          ],
        },
      });
    });
    isFBQTrackingEnabled(() => {
      window.fbq('track', 'AddToCart', {
        currency: product.currency,
        value: product.priceInclVat,
        content_ids: [product.uuid],
        content_type: 'product',
        contents: [
          {
            id: product.uuid,
            currency: product.currency,
            price: product.priceInclVat,
            quantity: 1,
          },
        ],
      });
    });
  },
  view_cart: (product: OperatorOfferSchemaType) => {
    isGTMTrackingEnabled(() => {
      window.gtag({ ecommerce: null }); // Clear the previous ecommerce object
      window.gtag('event', 'view_cart', {
        ecommerce: {
          currency: product.currency,
          value: product.priceInclVat,
          items: [
            {
              item_id: product.uuid,
              currency: product.currency,
              price: product.priceInclVat,
              quantity: 1,
              index: 0,
            },
          ],
        },
      });
    });
  },
  begin_checkout: (product: Offer) => {
    isGTMTrackingEnabled(() => {
      window.gtag({ ecommerce: null }); // Clear the previous ecommerce object
      window.gtag('event', 'begin_checkout', {
        ecommerce: {
          currency: product.currency,
          value: product.priceInclVat,
          items: [
            {
              item_id: product.uuid,
              currency: product.currency,
              price: product.priceInclVat,
              quantity: 1,
              index: 0,
            },
          ],
        },
      });
    });
  },
  add_payment_info: (product: Offer) => {
    isGTMTrackingEnabled(() => {
      window.gtag({ ecommerce: null }); // Clear the previous ecommerce object
      window.gtag('event', 'add_payment_info', {
        ecommerce: {
          currency: product.currency,
          value: product.priceInclVat,
          items: [
            {
              item_id: product.uuid,
              currency: product.currency,
              price: product.priceInclVat,
              quantity: 1,
              index: 0,
            },
          ],
        },
      });
    });
  },
  purchase: (order: OrderPublicDtoSchemaType, offerUuid: string) => {
    isGTMTrackingEnabled(() => {
      window.gtag({ ecommerce: null }); // Clear the previous ecommerce object
      window.gtag('event', 'purchase', {
        ecommerce: {
          currency: order.currency,
          value: order.priceInclVat,
          transaction_id: order.bookingNumber,
          items: [
            {
              item_id: offerUuid,
              currency: order.currency,
              price: order.priceInclVat,
              quantity: 1,
            },
          ],
        },
      });
    });
    isFBQTrackingEnabled(() => {
      window.fbq('track', 'Purchase', {
        currency: order.currency,
        value: order.priceInclVat,
        content_ids: [offerUuid],
        content_type: 'product',
        num_items: 1,
        contents: [
          { id: offerUuid, currency: order.currency, price: order.priceInclVat, quantity: 1 },
        ],
      });
    });
  },
  generate_lead: () => {
    isGTMTrackingEnabled(() => {
      window.gtag('event', 'generate_lead');
    });
  },
};
