import { Link as RouterLink } from 'react-router-dom';
import { colors } from 'husky-shared-fe-components';
import { useTheme } from '@mui/material/styles';
import { Button, Divider, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'utils/routes';
import FbIcon from '@/assets/fb-icon.svg?react';
import LinkedInIcon from '@/assets/linkedin-icon.svg?react';

function Footer() {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();

  return (
    <Grid
      container
      spacing={1}
      sx={{
        padding: `74px ${smallScreen ? '16px' : '112px'} 48px`,
        position: 'relative',
        display: 'flex',
        background: colors.primary,
        justifyContent: 'center',
        '&.MuiGrid-root .MuiButton-root': {
          color: colors.secondary,
          padding: 0,
          fontSize: '16px',
          lineHeight: '24px',
          height: '24px',
          fontWeight: 500,
        },
      }}
    >
      <Grid container item maxWidth='xl'>
        <Grid item lg={4} xs={12}>
          <Button
            variant='text'
            sx={{
              marginRight: '16px',
              textDecoration: 'none',
              '&:hover': { textDecoration: 'underline' },
            }}
            component={RouterLink}
            to={ROUTES.BaseRoutes.BECOME_OPERATOR}
          >
            {t('Menu.Navigation.become_operator.title')}
          </Button>
          <Button
            variant='text'
            sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
            component={RouterLink}
            to={ROUTES.BaseRoutes.CONTACT}
          >
            {t('Menu.Navigation.contact.title')}
          </Button>
        </Grid>
        <Grid item lg={8} xs={12} textAlign='right' mt={1}>
          <Button
            variant='text'
            sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
            component={RouterLink}
            to={ROUTES.BaseRoutes.FAQ}
          >
            {t('Menu.Navigation.faq.title')}
          </Button>
          <Button
            variant='text'
            sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
            component={RouterLink}
            to={ROUTES.BaseRoutes.TERMS_OF_SERVICE}
          >
            {t('Menu.Navigation.toc.title')}
          </Button>
          <Button
            variant='text'
            sx={{
              margin: '0 16px',
              textDecoration: 'none',
              '&:hover': { textDecoration: 'underline' },
            }}
            component={RouterLink}
            to={ROUTES.BaseRoutes.PRIVACY_POLICY}
          >
            {t('Menu.Navigation.privacy.title')}
          </Button>
          <Button
            variant='text'
            sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
            component={RouterLink}
            to={ROUTES.BaseRoutes.COOKIES}
          >
            {t('Menu.Navigation.cookies.title')}
          </Button>
        </Grid>
        <Grid container item xs={12} mt={1} gap={1}>
          <Link href='https://www.facebook.com/MobiferBussiTellimine/' target='_blank'>
            <FbIcon />
          </Link>
          <Link href='https://www.linkedin.com/company/mobifer/' target='_blank'>
            <LinkedInIcon />
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ margin: '26px 0 32px', borderColor: colors.gray6 }} />
        </Grid>
        <Grid item xs={12}>
          <Typography fontSize='16px' lineHeight='24px' variant='body2' color={colors.gray6}>
            {t('Menu.Navigation.copyright.title', { year: new Date().getFullYear() })}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Footer;
