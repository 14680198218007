import { useState } from 'react';
import {
  ControlledField,
  PhoneInputField,
  AutocompleteField,
  CheckboxInputField,
} from 'husky-shared-fe-components';
import { UseFormReturn } from 'react-hook-form';
import { Stack } from '@mui/system';
import { IdValueDtoLongString } from 'utils/data-types';
import { NewDriverSchemaType, EditOrderRequestSchemaType } from './types';

const newDriver = {
  contactInfo: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
};

function OrderDriverFormFields({
  formInstance,
  drivers,
}: {
  formInstance: UseFormReturn<EditOrderRequestSchemaType>;
  drivers: IdValueDtoLongString[];
}) {
  const { watch, setValue, clearErrors, control } = formInstance;
  const [pickExistingDriver, driverIdWatch, newDriverWatch] = watch([
    'pickExistingDriver',
    'driverId',
    'newDriver',
  ]);

  const [prevDriverId, setPrevDriverId] = useState<number | null>(null);
  const [prevNewDriver, setPrevNewDriver] = useState<NewDriverSchemaType | null>(newDriver);

  const handleToggleExistingDriver = (isTruthy: boolean) => {
    setValue('pickExistingDriver', isTruthy);

    if (isTruthy) {
      setValue('newDriver', null);
      setValue('driverId', prevDriverId);
      setPrevNewDriver(newDriverWatch);
      clearErrors('newDriver');
    } else {
      setValue('driverId', null);
      setValue('newDriver', prevNewDriver);
      setPrevDriverId(driverIdWatch);
    }
  };

  return (
    <Stack direction='column' gap={1}>
      {drivers.length > 0 && (
        <ControlledField
          control={control}
          name='pickExistingDriver'
          render={(renderProps) => (
            <CheckboxInputField
              {...renderProps}
              fieldProps={{
                onChange: (e) => handleToggleExistingDriver(e.target.checked),
              }}
              translationKey='pickExistingDriver'
            />
          )}
        />
      )}
      {pickExistingDriver && (
        <ControlledField
          control={control}
          name='driverId'
          render={(renderProps) => (
            <AutocompleteField
              {...renderProps}
              field={{
                ...renderProps.field,
              }}
              options={drivers.map((driver) => ({ label: driver.value, value: driver.id }))}
              fieldProps={{
                onChange: (_, newValue: any) => {
                  if (!newValue) return;
                  setValue('driverId', newValue.value);
                },
                disableClearable: false,
              }}
            />
          )}
        />
      )}
      {!pickExistingDriver && (
        <>
          <ControlledField
            control={control}
            name='newDriver.contactInfo.firstName'
            translationKey='firstName'
            fieldProps={{ InputProps: { autoComplete: 'given-name' } }}
          />
          <ControlledField
            control={control}
            name='newDriver.contactInfo.lastName'
            translationKey='lastName'
            fieldProps={{ InputProps: { autoComplete: 'family-name' } }}
          />
          <ControlledField
            control={control}
            name='newDriver.contactInfo.email'
            translationKey='email'
            fieldProps={{
              InputProps: {
                type: 'email',
                autoComplete: 'email',
              },
            }}
          />
          <ControlledField
            control={control}
            name='newDriver.contactInfo.phone'
            render={(renderProps) => <PhoneInputField {...renderProps} translationKey='phone' />}
          />
        </>
      )}
    </Stack>
  );
}

export default OrderDriverFormFields;
