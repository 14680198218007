import { Box } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';
import AppMenu from 'components/AppMenu';
import Footer from 'components/Footer';
import {
  useOrdersOperatorTokenDriverCandidatesQuery,
  useOrdersOperatorTokenQuery,
} from 'api/queries/orders';
import { ROUTES } from 'utils/routes';
import { PageLoader } from 'husky-shared-fe-components';
import OrderDriverForm from 'components/forms/OrderDriverForm/OrderDriverForm';

function OrderDriverView() {
  const { token } = useParams();
  const {
    data: order,
    isError,
    isSuccess: isOrderSuccess,
    isLoading: isOrderLoading,
  } = useOrdersOperatorTokenQuery(token);
  const {
    data: drivers = [],
    isSuccess: isDriversSuccess,
    isLoading: isDriversLoading,
  } = useOrdersOperatorTokenDriverCandidatesQuery(order ? token : undefined); // Only query when order is ready
  const isLoading = isOrderLoading || isDriversLoading;
  const isSuccess = isOrderSuccess && isDriversSuccess;

  if (isError) return <Navigate to={ROUTES.BaseRoutes.ROOT} replace />;

  // Find the ride by 0 index - Currently we do not have the ability to assign different driver and vehicle to separate rides
  const ride = order?.rides[0];
  if (ride == null) return null;

  const defaultDriverId = ride?.driver?.id ?? null; // Make sure to coerce to 'null' as form does not accept 'undefined'

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <AppMenu />
      {isLoading && (
        <Box display='flex' flex={1} flexGrow={1}>
          <PageLoader />
        </Box>
      )}
      {isSuccess && (
        <OrderDriverForm
          order={order!}
          drivers={drivers}
          defaultValues={{
            driverId: defaultDriverId,
            pickExistingDriver: drivers.length > 0,
          }}
        />
      )}
      <Footer />
    </Box>
  );
}

export default OrderDriverView;
