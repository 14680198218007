import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import {
  ControlledField,
  DriverAccommodationField,
  FormControlGroup,
} from 'husky-shared-fe-components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { usePutOffersClientDataMutation } from 'api/queries/offers';
import { OrderAccommodationSchemaType, orderAccommodationSchema } from '../types';

function OrderAccommodationStepForm({
  onValid,
  onBack,
  defaultValues,
}: {
  onValid: SubmitHandler<OrderAccommodationSchemaType>;
  onBack: (data: OrderAccommodationSchemaType) => void;
  defaultValues?: OrderAccommodationSchemaType;
}) {
  const tInstance = useTranslation();
  const { bookingNumber } = useParams();
  const { t } = tInstance;
  const {
    handleSubmit,
    control,
    getValues,
    formState: { isSubmitting, isValidating, isLoading, errors },
  } = useForm<OrderAccommodationSchemaType>({
    defaultValues: {
      driverAccommodationType: undefined,
      ...defaultValues,
    },
    resolver: zodResolver(orderAccommodationSchema),
  });
  const putOffersClientDataMutation = usePutOffersClientDataMutation(bookingNumber!);

  const handleOnValid = async (data: OrderAccommodationSchemaType) => {
    try {
      await putOffersClientDataMutation.mutateAsync(data);
      return onValid(data);
    } catch (err) {
      // TODO: Sentry error
      return Promise.reject(err);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleOnValid)}>
      <Stack direction='column' spacing={1} width={['auto', '350px']}>
        <FormControlGroup error={errors.driverAccommodationType}>
          <ControlledField
            control={control}
            name='driverAccommodationType'
            // TODO: Refactor DriverAccommodationField to adhere to new form standard
            render={(renderProps) => <DriverAccommodationField t={t} {...renderProps.field} />}
          />
        </FormControlGroup>
      </Stack>
      <Stack direction='row' justifyContent='space-between' spacing={1} mt='2rem'>
        <Button
          type='button'
          onClick={() => onBack(getValues())}
          variant='outlined'
          disabled={isSubmitting || isValidating || isLoading}
        >
          {t('back')}
        </Button>
        <Button
          type='submit'
          disabled={isSubmitting || isValidating || isLoading}
          variant='contained'
        >
          {t('continue')}
        </Button>
      </Stack>
    </form>
  );
}

export default OrderAccommodationStepForm;
