import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import {
  CheckboxInputField,
  ClientType,
  ControlledField,
  Offer,
  PhoneInputField,
  TabButtons,
  colors,
} from 'husky-shared-fe-components';
import { SubmitHandler, UseFormReturn, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { track } from 'utils/tracking';
import { usePutOffersClientDataMutation } from 'api/queries/offers';
import { OfferClientDataSchemaType } from 'components/forms/types';
import {
  BusinessClientContactInfoSchemaType,
  ClientContactInfoSchemaType,
  PrivateClientContactInfoSchemaType,
  businessClientContactInfoSchema,
  privateClientContactInfoSchema,
} from '../types';

function BillingEntityFormFields({ formInstance }: { formInstance: UseFormReturn<any> }) {
  const { t } = useTranslation();
  const { control } = formInstance;

  return (
    <Stack direction='column' spacing={1}>
      <Typography variant='body2' color={colors.primaryBlue1} fontWeight={500}>
        {t('OrderView.steps.clientDetails.invoiceDetails.title')}
      </Typography>
      <ControlledField
        control={control}
        name='billingEntity.companyName'
        fieldProps={{ InputProps: { autoComplete: 'organization' } }}
      />
      <ControlledField
        control={control}
        name='billingEntity.address'
        fieldProps={{ InputProps: { autoComplete: 'address' } }}
      />
      <ControlledField
        control={control}
        name='billingEntity.regCode'
        fieldProps={{ InputProps: { autoComplete: 'regCode' } }}
      />
      <ControlledField
        control={control}
        name='billingEntity.billingEmail'
        fieldProps={{
          InputProps: {
            type: 'email',
            autoComplete: 'email',
          },
        }}
      />
    </Stack>
  );
}

function ContactInfoStepForm({
  onValid,
  defaultValues,
}: {
  onValid: SubmitHandler<PrivateClientContactInfoSchemaType>;
  defaultValues?: PrivateClientContactInfoSchemaType;
}) {
  const tInstance = useTranslation();
  const { t, i18n } = tInstance;
  const { bookingNumber } = useParams();
  const formInstance = useForm<PrivateClientContactInfoSchemaType>({
    defaultValues: {
      // Do not spead the entire object as it will overwrite with a different clientType
      isEInvoiceEnabled: true,
      ...defaultValues,
      client: {
        contactInfo: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          ...defaultValues?.client?.contactInfo,
        },
        ...defaultValues?.client,
        type: ClientType.PRIVATE,
      },
    },
    resolver: zodResolver(privateClientContactInfoSchema),
  });
  const {
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting, isValidating, isLoading },
  } = formInstance;
  const putOffersClientDataMutation = usePutOffersClientDataMutation(bookingNumber!);
  const isBillingEntitySameAsDefault = watch('isBillingEntitySameAsDefault');

  const handleOnValid = async (data: PrivateClientContactInfoSchemaType) => {
    try {
      // TODO: Fix typing
      await putOffersClientDataMutation.mutateAsync({
        ...data,
        client: {
          ...data.client,
          lang: i18n.language,
        },
      } as unknown as OfferClientDataSchemaType);
      return onValid(data);
    } catch (err) {
      // TODO: Sentry error
      return Promise.reject(err);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleOnValid)}>
      <Stack direction='column' spacing={1}>
        <ControlledField
          control={control}
          name='client.contactInfo.firstName'
          fieldProps={{ InputProps: { autoComplete: 'given-name' } }}
        />
        <ControlledField
          control={control}
          name='client.contactInfo.lastName'
          fieldProps={{ InputProps: { autoComplete: 'family-name' } }}
        />
        <ControlledField
          control={control}
          name='client.contactInfo.email'
          fieldProps={{
            helperText: '',
            InputProps: {
              type: 'email',
              autoComplete: 'email',
            },
          }}
        />
        <ControlledField
          control={control}
          name='client.contactInfo.phone'
          render={(renderProps) => <PhoneInputField {...renderProps} />}
        />
        <ControlledField
          control={control}
          name='isEInvoiceEnabled'
          render={(renderProps) => (
            <CheckboxInputField {...renderProps} fieldProps={{ disabled: true }} />
          )}
        />
        <ControlledField
          control={control}
          name='isBillingEntitySameAsDefault'
          render={(renderProps) => <CheckboxInputField {...renderProps} />}
        />
        {!isBillingEntitySameAsDefault && <BillingEntityFormFields formInstance={formInstance} />}
      </Stack>
      <Button
        type='submit'
        disabled={isSubmitting || isValidating || isLoading}
        variant='contained'
        sx={{ mt: '2rem', float: 'right' }}
      >
        {t('continue')}
      </Button>
    </form>
  );
}

function BusinessContactInfoStepForm({
  onValid,
  defaultValues,
}: {
  onValid: SubmitHandler<BusinessClientContactInfoSchemaType>;
  defaultValues?: BusinessClientContactInfoSchemaType;
}) {
  const tInstance = useTranslation();
  const { t, i18n } = tInstance;
  const { bookingNumber } = useParams();
  const formInstance = useForm<BusinessClientContactInfoSchemaType>({
    defaultValues: {
      // Do not spead the entire object as it will overwrite with a different clientType
      isEInvoiceEnabled: true,
      ...defaultValues,
      client: {
        companyName: '',
        companyEmail: '',
        companyPhone: '',
        address: '',
        regCode: '',
        contactInfo: {
          email: '',
          firstName: '',
          lastName: '',
          phone: '',
        },
        ...defaultValues?.client,
        type: ClientType.BUSINESS,
      },
    },
    resolver: zodResolver(businessClientContactInfoSchema),
  });
  const {
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting, isValidating, isLoading },
  } = formInstance;
  const isBillingEntitySameAsDefault = watch('isBillingEntitySameAsDefault');
  const putOffersClientDataMutation = usePutOffersClientDataMutation(bookingNumber!);

  const handleOnValid = async (data: BusinessClientContactInfoSchemaType) => {
    try {
      await putOffersClientDataMutation.mutateAsync({
        ...data,
        client: {
          ...data.client,
          lang: i18n.language,
        },
      } as unknown as OfferClientDataSchemaType);
      return onValid(data);
    } catch (err) {
      // TODO: Sentry error
      return Promise.reject(err);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleOnValid)}>
      <Stack direction='column' spacing={1}>
        <ControlledField
          control={control}
          name='client.companyName'
          fieldProps={{ InputProps: { autoComplete: 'organization' } }}
        />
        <ControlledField
          control={control}
          name='client.address'
          fieldProps={{ InputProps: { autoComplete: 'address' } }}
        />
        <ControlledField
          control={control}
          name='client.regCode'
          fieldProps={{ InputProps: { autoComplete: 'regCode' } }}
        />
        <ControlledField
          control={control}
          name='client.companyEmail'
          fieldProps={{
            InputProps: {
              type: 'email',
              autoComplete: 'email',
            },
          }}
        />
        <ControlledField
          control={control}
          name='client.companyPhone'
          render={(renderProps) => <PhoneInputField {...renderProps} />}
        />
        <Box
          sx={{
            display: 'flex',
            height: '1px',
            width: '100%',
            background: colors.gray4,
            my: '1rem!important',
          }}
        />
        <Typography variant='body2' color={colors.primaryBlue1} fontWeight={500}>
          {t('OrderView.steps.company.contactPerson')}
        </Typography>
        <ControlledField
          control={control}
          name='client.contactInfo.firstName'
          fieldProps={{ InputProps: { autoComplete: 'given-name' } }}
        />
        <ControlledField
          control={control}
          name='client.contactInfo.lastName'
          fieldProps={{ InputProps: { autoComplete: 'family-name' } }}
        />
        <ControlledField
          control={control}
          name='client.contactInfo.email'
          fieldProps={{
            helperText: '',
            InputProps: {
              type: 'email',
              autoComplete: 'email',
            },
          }}
        />
        <ControlledField
          control={control}
          name='client.contactInfo.phone'
          render={(renderProps) => <PhoneInputField {...renderProps} />}
        />
        <ControlledField
          control={control}
          name='isEInvoiceEnabled'
          render={(renderProps) => (
            <CheckboxInputField {...renderProps} fieldProps={{ disabled: true }} />
          )}
        />
        <ControlledField
          control={control}
          name='isBillingEntitySameAsDefault'
          render={(renderProps) => <CheckboxInputField {...renderProps} />}
        />
        {!isBillingEntitySameAsDefault && <BillingEntityFormFields formInstance={formInstance} />}
      </Stack>
      <Button
        type='submit'
        disabled={isSubmitting || isValidating || isLoading}
        variant='contained'
        sx={{ mt: '2rem', float: 'right' }}
      >
        {t('continue')}
      </Button>
    </form>
  );
}

function OrderContactDetailsStepForm({
  onValid,
  defaultValues,
  activeTab,
  setActiveTab,
  offer,
}: {
  onValid: SubmitHandler<ClientContactInfoSchemaType>;
  defaultValues?: ClientContactInfoSchemaType;
  activeTab: ClientType;
  setActiveTab: (tab: ClientType) => void;
  offer: Offer;
}) {
  const { t } = useTranslation();

  const handleOnValid = (
    data: PrivateClientContactInfoSchemaType | BusinessClientContactInfoSchemaType
  ) => {
    // Track the checkout
    track.begin_checkout(offer);

    onValid(data);
  };

  return (
    <Box textAlign='left'>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <TabButtons
          tabs={Object.values(ClientType).map((k) => t(`ClientType.${k}`))}
          activeTab={Object.values(ClientType).indexOf(activeTab)}
          setActiveTab={(idx) => setActiveTab(Object.values(ClientType)[idx])}
        />
      </Box>
      <Box sx={{ mb: '24px' }} />
      {activeTab === ClientType.PRIVATE && (
        <ContactInfoStepForm
          onValid={handleOnValid}
          defaultValues={defaultValues as PrivateClientContactInfoSchemaType}
        />
      )}
      {activeTab === ClientType.BUSINESS && (
        <BusinessContactInfoStepForm
          onValid={handleOnValid}
          defaultValues={defaultValues as BusinessClientContactInfoSchemaType}
        />
      )}
    </Box>
  );
}

export default OrderContactDetailsStepForm;
