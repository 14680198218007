import { Paper, Box, Typography } from '@mui/material';
import { colors, formatCurrency, DriverAccommodationType } from 'husky-shared-fe-components';
import { useTranslation } from 'react-i18next';
import { trackedChangesKeys } from '@/views/OrderEditView';
import { Circle, ArrowRightAlt } from '@mui/icons-material';
import { OrderPublicDtoSchemaType } from './forms/types';

type TrackedChangesKeys = (typeof trackedChangesKeys)[number];

interface ChangedFieldProps {
  valueKey: TrackedChangesKeys;
  order: OrderPublicDtoSchemaType;
  value: any;
}

const getValueString = (value: string) => {
  switch (value) {
    case DriverAccommodationType.BY_CLIENT:
      return 'FormFields.driverAccommodationType.options[0].value';
    case DriverAccommodationType.BY_OPERATOR:
      return 'FormFields.driverAccommodationType.options[1].value';
    default:
      return value;
  }
};

const getchangedFieldLabel = (key: string) => {
  switch (key) {
    case 'rides[0]legs[0]startTime':
      return 'FormFields.startTime.label';
    case 'rides[0]legs[1]startTime':
      return 'FormFields.returnTime.label';
    case 'rides[0]legs[0]startLocation.address':
      return 'FormFields.startLocationPickupAddress.label';
    case 'rides[0]legs[1]startLocation.address':
      return 'FormFields.endLocation.label';
    default:
      return `FormFields.${key}.label`;
  }
};

function ChangedField({ valueKey, value }: ChangedFieldProps) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        padding: '4px 0',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Circle sx={{ fontSize: '8px', mr: '6px' }} />
        <Typography
          sx={{
            fontSize: '14px',
            color: colors.gray2,
          }}
        >
          {t(getchangedFieldLabel(valueKey))}
        </Typography>
      </Box>
      <Typography
        ml='16px'
        display='flex'
        alignItems='center'
        sx={{
          fontSize: '14px',
          color: colors.gray2,
          lineHeight: '20px',
        }}
      >
        <span style={{ color: colors.gray3, textDecoration: 'line-through' }}>
          {t(getValueString(value.original), getValueString(value.original))}
        </span>
        <ArrowRightAlt sx={{ m: '0 4px' }} />
        <span>{t(getValueString(value.current), getValueString(value.current))}</span>
      </Typography>
    </Box>
  );
}

interface Props {
  order: OrderPublicDtoSchemaType;
  trackedChanges: { [key: string]: any };
}

function OrderEditChangeTracker({ order, trackedChanges }: Props) {
  const { t, i18n } = useTranslation();
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: '-20px',
        height: 20,
        display: {
          xs: 'none',
          xl: 'block',
        },
      }}
    >
      <Box sx={{ position: 'relative', width: '100%', maxWidth: '400px' }}>
        <Box sx={{ position: 'fixed' }}>
          <Paper elevation={3} sx={{ borderRadius: '15px', minWidth: '300px' }}>
            <Box padding='12px 24px'>
              <Typography sx={{ fontWeight: 700, fontSize: '16px', lineHeight: '20px' }}>
                # {order.bookingNumber}
              </Typography>
              <Box id='bookingChangesContainer'>
                {Object.entries(trackedChanges).map(([key, value]) => (
                  <ChangedField key={key} valueKey={key} value={value} order={order} />
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                padding: '16px 24px',
                background: colors.tiffanyBlue5,
                borderBottomRightRadius: '15px',
                borderBottomLeftRadius: '15px',
                textAlign: 'center',
                color: colors.gray1,
                fontSize: '14px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span>{t('terms.attributes.price.total.inclVat')}</span>
              <span
                style={{
                  fontSize: '20px',
                  fontWeight: 700,
                  color: colors.primary,
                  marginLeft: '6px',
                }}
              >
                {formatCurrency(order.price, i18n.language)}
              </span>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}

export default OrderEditChangeTracker;
