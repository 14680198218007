import { useForm } from 'react-hook-form';
import { ControlledField, PhoneInputField, TextareaInputField } from 'husky-shared-fe-components';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/system';
import { Button } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import { track } from 'utils/tracking';
import { toast } from 'react-hot-toast';
import { usePostFeedbackMutation } from 'api/queries/feedback';
import { FeedbackRequestSchemaType, feedbackRequestSchema } from './types';

function ContactForm() {
  const { t, i18n } = useTranslation();
  const formInstance = useForm<FeedbackRequestSchemaType>({
    defaultValues: {
      email: '',
      message: '',
      name: '',
      phoneNumber: '',
      lang: i18n.language,
    },
    resolver: zodResolver(feedbackRequestSchema),
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isValidating, isLoading },
  } = formInstance;
  const postFeedbackMutation = usePostFeedbackMutation();

  const onValid = async (data: FeedbackRequestSchemaType) => {
    await postFeedbackMutation.mutateAsync(data);
    track.generate_lead();
    toast.success(t('Toast.success'));
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onValid)}>
      <Stack direction='column' spacing={1}>
        <ControlledField
          control={control}
          name='name'
          fieldProps={{ InputProps: { autoComplete: 'given-name' } }}
        />
        <ControlledField
          control={control}
          name='email'
          fieldProps={{
            InputProps: {
              type: 'email',
              autoComplete: 'email',
            },
          }}
        />
        <ControlledField
          control={control}
          name='phoneNumber'
          render={(renderProps) => <PhoneInputField {...renderProps} translationKey='phone' />}
        />
        <ControlledField
          control={control}
          name='message'
          render={(renderProps) => (
            <TextareaInputField
              fieldProps={{
                minRows: 2,
              }}
              {...renderProps}
            />
          )}
        />
      </Stack>
      <Button
        type='submit'
        disabled={isSubmitting || isValidating || isLoading}
        variant='contained'
        sx={{ mt: '2rem' }}
      >
        {t('submit')}
      </Button>
    </form>
  );
}

export default ContactForm;
