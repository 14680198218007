import React, { StrictMode, Suspense } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/system';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import * as Sentry from '@sentry/react';
import SessionProvider from './SessionProvider';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import StableQueryClient from 'setup/StableQueryClient';
import { I18nextProvider } from 'react-i18next';
import { localI18nInstance } from './i18n';
import theme from './setup/muiTheme';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@/index.css';
import { isProd } from './utils/helpers';

Sentry.init({
  dsn: 'https://cbd199a672c37e400e2a2459adec1555@o4505781080948736.ingest.sentry.io/4505794060091392',
  tunnel: 'https://sentry.mobifer.com/api/4505794060091392/envelope/',
  enabled: isProd,
  release: window.SENTRY_RELEASE?.id,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/sentry.mobifer\.com\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

LicenseInfo.setLicenseKey(
  'fc924803492ac9eb03660ee53dce4698Tz05MzYxNSxFPTE3NTE2MTEwMzQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <Suspense>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <StyledEngineProvider injectFirst>
          <StableQueryClient>
            <I18nextProvider i18n={localI18nInstance}>
              <SessionProvider>
                <App />
              </SessionProvider>
            </I18nextProvider>
          </StableQueryClient>
        </StyledEngineProvider>
      </ThemeProvider>
    </Suspense>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
