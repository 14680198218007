import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import {
  fetchOffersByBookingNumber,
  fetchOffersPaymentStatus,
  postOffers,
  postOffersAdditionalOffersEmail,
  postOffersPaymentStart,
  putOffersClientData,
} from 'api/requests/requests';
import { STALE_FIVE_MINUTES, STALE_ONE_HOUR } from 'api/types';
import { AxiosError, AxiosResponse } from 'axios';
import { DEFAULT_REQUEST_INTERVAL_IN_MS } from 'components/OffersInquiry';
import { OfferClientDataSchemaType } from 'components/forms/types';
import {
  NoOffersEmailInputSchemaType,
  OfferSchemaType,
  StartPaymentRequestSchemaType,
  StartPaymentResponseSchemaType,
} from 'husky-shared-fe-components';
import { OfferPayload } from 'utils/data-types';

export const offersQueryKeys = {
  all: ['offers'] as const,
  detail: (bookingNumber: string) => [...offersQueryKeys.all, bookingNumber] as const,
  clientData: (bookingNumber: string) =>
    [...offersQueryKeys.detail(bookingNumber), 'client-data'] as const,
  paymentStatus: (bookingNumber: string) =>
    [...offersQueryKeys.detail(bookingNumber), 'payment', 'status'] as const,
  paymentStart: (bookingNumber: string) =>
    [...offersQueryKeys.detail(bookingNumber), 'payment', 'start'] as const,
  additionalOffersEmail: (bookingNumber: string) =>
    [...offersQueryKeys.detail(bookingNumber), 'additionaloffersemail'] as const,
};

export function usePutOffersClientDataMutation(bookingNumber: string) {
  return useMutation<OfferSchemaType, AxiosError, OfferClientDataSchemaType>({
    mutationKey: offersQueryKeys.clientData(bookingNumber),
    mutationFn: (v) => putOffersClientData(bookingNumber, v).then((d) => d.data),
  });
}

export function useOffersPaymentStatusQuery(bookingNumber?: string) {
  return useQuery({
    queryKey: offersQueryKeys.paymentStatus(bookingNumber!),
    queryFn: () => fetchOffersPaymentStatus(bookingNumber!),
    enabled: bookingNumber != null,
    staleTime: STALE_FIVE_MINUTES,
    select: ({ data }) => data,
    // We refetch this query until the condition is met
    refetchInterval: (query) => {
      const { orderCreated, expired, failed } = query.state.data?.data ?? {};
      const isAnyTruthy = [orderCreated, expired, failed].some(Boolean);
      if (isAnyTruthy) return false;
      return DEFAULT_REQUEST_INTERVAL_IN_MS;
    },
  });
}

export function usePostOffersMutation() {
  return useMutation<OfferSchemaType, AxiosError, OfferPayload>({
    mutationKey: offersQueryKeys.all,
    mutationFn: (data) => postOffers(data).then((d) => d.data),
  });
}

export function useOffersByBookingNumberQuery(
  bookingNumber?: string,
  options?: Omit<
    UseQueryOptions<AxiosResponse<OfferSchemaType>, AxiosError, string | undefined>,
    'queryKey' | 'select'
  >
) {
  return useQuery({
    queryKey: offersQueryKeys.detail(bookingNumber!),
    queryFn: () => fetchOffersByBookingNumber(bookingNumber!),
    enabled: bookingNumber != null,
    staleTime: STALE_ONE_HOUR,
    select: ({ data }) => data,
    ...options,
  });
}

export function usePostOffersPaymentStartMutation(bookingNumber: string) {
  return useMutation<StartPaymentResponseSchemaType, AxiosError, StartPaymentRequestSchemaType>({
    mutationKey: offersQueryKeys.paymentStart(bookingNumber),
    mutationFn: (v) => postOffersPaymentStart(bookingNumber, v).then((d) => d.data),
  });
}

export function useOffersAdditionalOffersEmailMutation(bookingNumber?: string) {
  return useMutation<number, AxiosError, NoOffersEmailInputSchemaType & { lang: string }>({
    mutationKey: offersQueryKeys.additionalOffersEmail(bookingNumber!),
    mutationFn: (v) => postOffersAdditionalOffersEmail(bookingNumber!, v).then((d) => d.status),
  });
}
