import { useState, useCallback, useRef, useEffect } from 'react';

function useTimer(initialDuration: number, { isEnabled }: { isEnabled: boolean }) {
  const [counter, setCounter] = useState(initialDuration);
  const [isRunning, setIsRunning] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const startTimer = useCallback(() => {
    setIsRunning(true);
    setCounter(initialDuration);

    timerRef.current = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter <= 1) {
          clearInterval(timerRef.current as NodeJS.Timeout);
          setIsRunning(false);
          return 0;
        }
        return prevCounter - 1;
      });
    }, 1000);
  }, [initialDuration]);

  const stopTimer = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    setIsRunning(false);
  }, []);

  useEffect(() => {
    if (isEnabled) {
      startTimer();
    } else {
      stopTimer();
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isEnabled, startTimer, stopTimer]);

  return { counter, isRunning, startTimer, stopTimer, resetTimer: startTimer };
}

export default useTimer;
