export enum CookieKeyEnum {
  JWT_TOKEN = 'jwt',
  REFRESH_TOKEN = 'refresh',

  // JWT_TOKEN_BUSINESS and REFRESH_TOKEN_BUSINESS is used on both landing and business, so this key needs to be separate
  JWT_TOKEN_BUSINESS = 'jwtBusiness',
  REFRESH_TOKEN_BUSINESS = 'refreshBusiness',
}

export enum MobiferProjectEnum {
  OPERATOR = 'operator',
  BUSINESS = 'corporate',
  SUPERADMIN = 'superadmin',
}

export enum ClientPaymentActiveTabEnum {
  BY_BANK = 'BY_BANK',
  BY_CARD = 'BY_CARD',
  BY_INVOICE = 'BY_INVOICE',
}

export enum TokenStatus {
  OK = 'OK',
  EXPIRED = 'EXPIRED',
  NOT_FOUND = 'NOT_FOUND',
}

export const COOKIE_CONSENT_KEY = 'cookieConsent';
export const COOKIE_CONSENT_TRUTHY = 'true';
export const COOKIE_CONSENT_TRUTHY_EXPIRES = 365;
export const COOKIE_CONSENT_FALSY = 'false';
export const COOKIE_CONSENT_FALSY_EXPIRES = 7;
