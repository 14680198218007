import { Box } from '@mui/system';
import AppMenu from 'components/AppMenu';
import Footer from 'components/Footer';
import ContactUsSection from 'components/landing/ContactUsSection';
import FAQSection from 'components/landing/FAQSection';
import { useTranslation } from 'react-i18next';

function FAQView() {
  const { t } = useTranslation();
  return (
    <>
      <AppMenu />
      <FAQSection
        title={t('FAQView.title')}
        subtitle={t('FAQView.lists.default.subtitle').toString()}
        FAQListKey='FAQView.lists.default.list'
      />
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <ContactUsSection />
      </Box>
      <Footer />
    </>
  );
}

export default FAQView;
