import { Grid, Paper, Typography } from '@mui/material';
import { colors } from 'husky-shared-fe-components';
import CreateRideOffersForm from 'components/forms/CreateRideOffersForm/CreateRideOffersForm';
import { useTranslation } from 'react-i18next';
import { useUIOfferFormPreset } from 'api/queries/ui';

function CreateRideOffers() {
  const { t } = useTranslation();
  const { offerFormPreset } = useUIOfferFormPreset();

  return (
    <Grid container maxWidth='lg' gap={4}>
      <Grid container item xs={12} gap={2}>
        <Grid item xs={12}>
          <Typography
            variant='h1'
            color='primary'
            sx={{
              whiteSpace: 'pre-line',
              fontWeight: 700,
              fontSize: {
                xs: '1.5rem',
                sm: '2rem',
                md: '2.5rem',
                lg: '3rem',
              },
            }}
          >
            {t('LandingView.hero.title')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            component='h2'
            variant='body1'
            sx={{
              color: colors.headerBlack,
              whiteSpace: {
                xs: 'auto',
                md: 'pre-line',
              },
              fontSize: {
                xs: '1rem',
                sm: '1.2rem',
                md: '1.5rem',
              },
            }}
          >
            {t('LandingView.hero.subtitle')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper
          elevation={3}
          sx={{
            padding: ['1rem', undefined, '2rem'],
            justifyContent: 'center',
            backgroundColor: '#FFF',
            width: '100%',
            border: `1px solid ${colors.tiffanyBlue3}`,
          }}
        >
          <CreateRideOffersForm defaultValues={offerFormPreset} />
        </Paper>
      </Grid>
    </Grid>
  );
}

export default CreateRideOffers;
