import { Close } from '@mui/icons-material';
import { Box, Divider, Drawer, IconButton, Toolbar } from '@mui/material';
import { DrawerMenuAppBar, MenuList, MobiferAppLogo } from 'husky-shared-fe-components';
import useMenuItems from 'utils/hooks/useMenuItems';
import { RouteItemKey } from 'utils/routes';
import { useUIDrawer } from 'api/queries/ui';

function AppMenu() {
  const { isMobileDrawerOpen, setIsMobileDrawerOpen } = useUIDrawer();
  const menuItems = useMenuItems();

  return (
    <>
      <DrawerMenuAppBar<RouteItemKey>
        isMobileDrawerOpen={isMobileDrawerOpen}
        setIsMobileDrawerOpen={setIsMobileDrawerOpen}
        menuItems={menuItems}
        showBreadcrumbs={false}
        slot={
          <Box sx={{ display: { xs: 'none', md: 'flex' }, li: { width: 'inherit' } }}>
            <MenuList
              menuList={menuItems}
              setIsMobileDrawerOpen={setIsMobileDrawerOpen}
              isHorizontal
            />
          </Box>
        }
      >
        <MobiferAppLogo env={window.MOBIFER_ENV} />
      </DrawerMenuAppBar>
      <Drawer
        open={isMobileDrawerOpen}
        onClose={() => setIsMobileDrawerOpen(false)}
        anchor='top'
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
        }}
      >
        <Toolbar disableGutters sx={{ px: 1, gap: 1, justifyContent: 'space-between' }}>
          <MobiferAppLogo env={window.MOBIFER_ENV} />
          <IconButton
            onClick={() => setIsMobileDrawerOpen(!isMobileDrawerOpen)}
            sx={{ display: { md: 'none' } }}
          >
            <Close color='primary' />
          </IconButton>
        </Toolbar>
        <Divider />
        <Box sx={{ p: 1, pb: 3, gap: 1, ul: { display: 'flex', flexDirection: 'column', gap: 1 } }}>
          <MenuList menuList={menuItems} setIsMobileDrawerOpen={setIsMobileDrawerOpen} />
        </Box>
      </Drawer>
    </>
  );
}

export default AppMenu;
