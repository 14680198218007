import { useEffect } from 'react';
import { Box } from '@mui/material';
import { MobiferLogo } from 'husky-shared-fe-components';
import { useParams } from 'react-router-dom';

function DeepLinkRedirectView() {
  const { subpath, id } = useParams();

  useEffect(() => {
    window.location.replace(`mobifer://${subpath}/${id}`);
  }, [subpath, id]);

  return (
    <Box p='12px 42px'>
      <MobiferLogo />
    </Box>
  );
}

export default DeepLinkRedirectView;
