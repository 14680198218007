import { ArrowRight, Logout } from '@mui/icons-material';
import {
  GlobeIcon,
  DrawerLanguagePicker,
  LanguageOptions,
  MenuItem,
  MenuItemWithLangKey,
  UserPermission,
  extendMenuItemByKey,
  SelectOption,
  ButtonDropdownSelector,
} from 'husky-shared-fe-components';
import { useTranslation } from 'react-i18next';
import { ROUTES, RouteItemKey } from 'utils/routes';
import { Button } from '@mui/material';
import { MobiferProjectEnum } from 'utils/enums';
import { getUrlByProject } from 'utils/helpers';
import { Link } from 'react-router-dom';
import { useAuthClientJwtQuery } from 'api/queries/auth';
import { useUIDrawer } from 'api/queries/ui';

function filterVisible<T>(items: MenuItem<T>[]): MenuItem<T>[] {
  return items.reduce((acc: MenuItem<T>[], item: MenuItem<T>) => {
    if (item.visible === undefined || item.visible === true) {
      const newItem: MenuItem<T> = {
        ...item,
        items: item.items ? filterVisible(item.items) : undefined,
      };
      acc.push(newItem);
    }
    return acc;
  }, []);
}

export function useLanguageMenuItems() {
  const langMenuItems: MenuItemWithLangKey<RouteItemKey>[] = [
    {
      itemKey: ROUTES.LanguageRoutes.LANGUAGE_ET,
      langKey: LanguageOptions.et,
      label: 'Eesti',
      icon: <ArrowRight fontSize='small' />,
      permission: UserPermission.WRITE_ALL,
    },
    {
      itemKey: ROUTES.LanguageRoutes.LANGUAGE_EN,
      langKey: LanguageOptions.en,
      label: 'English',
      icon: <ArrowRight fontSize='small' />,
      permission: UserPermission.WRITE_ALL,
    },
  ];

  return langMenuItems;
}

function useSignupOptions() {
  const { t } = useTranslation();
  const options: SelectOption[] = [
    {
      label: t('LoginView.signup.options.corporate'),
      value: MobiferProjectEnum.BUSINESS,
    },
    {
      label: t('LoginView.signup.options.operator'),
      value: MobiferProjectEnum.OPERATOR,
    },
  ];

  return options;
}

function useMenuItems() {
  const tInstance = useTranslation();
  const signupOptions = useSignupOptions();
  const { isSuccess: isAuthSuccess } = useAuthClientJwtQuery();
  const { setIsMobileDrawerOpen } = useUIDrawer();
  const { t, i18n } = tInstance;
  const langMenuItems = useLanguageMenuItems();
  const isLoggedIn = isAuthSuccess;

  const menuItems: MenuItem<RouteItemKey>[][] = [
    [
      {
        itemKey: ROUTES.BaseRoutes.ROOT,
        label: t('Menu.Navigation.home.title'),
        to: ROUTES.BaseRoutes.ROOT,
        permission: UserPermission.WRITE_ALL,
        visible: true,
      },
      {
        itemKey: ROUTES.BaseRoutes.BECOME_OPERATOR,
        label: t('Menu.Navigation.become_operator.title'),
        to: ROUTES.BaseRoutes.BECOME_OPERATOR,
        permission: UserPermission.WRITE_ALL,
        visible: true,
      },
      {
        itemKey: ROUTES.BaseRoutes.FAQ,
        label: t('Menu.Navigation.faq.title'),
        to: ROUTES.BaseRoutes.FAQ,
        permission: UserPermission.WRITE_ALL,
        visible: true,
      },
      {
        itemKey: ROUTES.BaseRoutes.CONTACT,
        label: t('Menu.Navigation.contact.title'),
        to: ROUTES.BaseRoutes.CONTACT,
        permission: UserPermission.WRITE_ALL,
        visible: true,
      },
      {
        itemKey: ROUTES.BaseRoutes.LANGUAGE,
        // label: t('Menu.Navigation.language.title'),
        label: langMenuItems.find((el) => el.langKey === i18n.language)?.label,
        icon: <GlobeIcon />,
        permission: UserPermission.WRITE_ALL,
        visible: true,
      },
      {
        itemKey: ROUTES.BaseRoutes.LOGIN,
        label: '',
        // to: ROUTES.BaseRoutes.LOGIN,
        component: (
          <Button
            LinkComponent={Link}
            variant='outlined'
            sx={{ width: 'max-content', ml: 1 }}
            {...{ to: ROUTES.BaseRoutes.LOGIN }}
          >
            {t('Menu.Navigation.login.title')}
          </Button>
        ),
        permission: UserPermission.WRITE_ALL,
        visible: !isLoggedIn,
      },
      {
        itemKey: ROUTES.BaseRoutes.SIGNUP,
        label: '',
        component: (
          <ButtonDropdownSelector
            label={t('Menu.Navigation.signup.title')}
            options={signupOptions}
            buttonProps={{
              sx: { width: 'max-content', ml: 1 },
            }}
            onSelect={(value) => {
              switch (value) {
                case MobiferProjectEnum.BUSINESS: {
                  window.location.href = `${getUrlByProject('corporate')}/sign-up?lang=${
                    i18n.language
                  }`;
                  break;
                }
                case MobiferProjectEnum.OPERATOR: {
                  window.location.href = `${getUrlByProject('operator')}/sign-up?lang=${
                    i18n.language
                  }`;
                  break;
                }
                default: {
                  break;
                }
              }
            }}
          />
        ),
        permission: UserPermission.WRITE_ALL,
        visible: !isLoggedIn,
      },
      {
        itemKey: ROUTES.BaseRoutes.LOGOUT,
        label: t('Menu.Navigation.logout.title'),
        to: [ROUTES.BaseRoutes.LOGOUT].join('/'),
        icon: <Logout fontSize='small' />,
        permission: UserPermission.WRITE_ALL,
        visible: isLoggedIn,
      },
    ],
  ];

  // We will add the DrawerLanguagePicker component to the MenuItemEnum.LANGUAGE item afterwards because it needs access to the whole 'menuItems' object
  const menuItemsWithLangComponent = extendMenuItemByKey(menuItems, ROUTES.BaseRoutes.LANGUAGE, {
    component: (
      <DrawerLanguagePicker<RouteItemKey>
        setIsMobileDrawerOpen={setIsMobileDrawerOpen}
        langMenuItemKey={ROUTES.BaseRoutes.LANGUAGE}
        menuItems={menuItems}
        languageMenuItems={langMenuItems}
        // TODO: When is logged in onLanguageChange should update the value in BE
        onLanguageChange={() => {}}
        menuProps={
          {
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            transformOrigin: { vertical: 'top', horizontal: 'left' },
          } as React.ComponentProps<typeof DrawerLanguagePicker>['menuProps']
        }
      />
    ),
  } as MenuItem<RouteItemKey> as any);

  const filteredArray = menuItemsWithLangComponent.map(filterVisible);

  return filteredArray;
}

export default useMenuItems;
