import {
  ControlledField,
  DriverAccommodationField,
  FileTypeEnum,
  FormControlGroup,
  InlineFileInputField,
  PassengerCountField,
  TextareaInputField,
  DateTimePickerInputField,
  getDateTimeLongFormatWithLocale,
  LocationInputField,
  RideOfferType,
} from 'husky-shared-fe-components';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Grid, Box, FormHelperText } from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import EditOrderSectionTitle from 'components/editOrder/EditOrderSectionTitle';
import { OrderPublicDtoSchemaType } from '../types';
import { useMapsGoogleConfigurationQuery } from 'api/queries/config';
import { EditOrderCombinedSchemaType } from '../types';

function GeneralOrderForm({
  formInstance,
  order,
}: {
  formInstance: UseFormReturn<EditOrderCombinedSchemaType>;
  order: OrderPublicDtoSchemaType;
}) {
  const tInstance = useTranslation();
  const { control, watch, getValues, setValue } = formInstance;
  const firstLegStartTime = getValues('rides.0.legs.0.startTime');
  const { errors } = formInstance.formState;
  const { i18n, t } = tInstance;
  const overnight = watch('overnight');
  const startTimeError = errors.rides?.[0]?.legs?.[0]?.startTime?.type;
  const returnTimeError = errors.rides?.[0]?.legs?.[1]?.startTime?.type;
  const startLocationError = errors.rides?.[0]?.legs?.[0]?.startLocation?.type;
  const destinationAddressError = errors.rides?.[0]?.legs?.[1]?.startLocation?.type;
  const handleRejectFile = (alertMessage: string) => toast.error(alertMessage);
  const { data: googleConfig } = useMapsGoogleConfigurationQuery();
  const { feApiKey } = googleConfig ?? {};
  const givenDate = dayjs(firstLegStartTime);
  const newMinDate = givenDate.subtract(30, 'minute');
  const newMaxDate = givenDate.add(30, 'minute');

  return (
    <Grid container columnSpacing={10} justifyContent='center'>
      <Grid item xs={12} md={6}>
        <Box id='tripDetailsSection'>
          <EditOrderSectionTitle
            title={t('ChangeOrderDetailsView.steps.tripDetails.title').toString()}
            subtitle={t('ChangeOrderDetailsView.steps.tripDetails.subtitle').toString()}
          />
          {feApiKey && (
            <>
              <ControlledField
                control={control}
                name='rides.0.legs.0.startLocation'
                render={(renderProps) => (
                  <LocationInputField
                    translationKey='startLocationPickupAddress'
                    {...renderProps}
                    apiKey={feApiKey}
                    fieldProps={{
                      onChange: (_event: any, value: any) => {
                        renderProps.field.onChange(value);
                        if (order.rides[0]?.legs[1]?.endLocation) {
                          setValue('rides.0.legs.1.endLocation', value);
                        }
                      },
                    }}
                  />
                )}
              />
              <FormHelperText error={Boolean(startLocationError)}>
                {t('ErrorCode.LOCATION_DIFFERENCE_TOO_BIG')}
              </FormHelperText>
              {order.rides[0]?.legs[1]?.startLocation &&
                order.type !== RideOfferType.MULTI_STOP && (
                  <>
                    <ControlledField
                      control={control}
                      name='rides.0.legs.1.startLocation'
                      render={(renderProps) => (
                        <LocationInputField
                          translationKey='endLocation'
                          {...renderProps}
                          apiKey={feApiKey}
                          fieldProps={{
                            onChange: (_event: any, value: any) => {
                              renderProps.field.onChange(value);
                              if (order.rides[0]?.legs[1]?.startLocation) {
                                setValue('rides.0.legs.0.endLocation', value);
                              }
                            },
                          }}
                        />
                      )}
                    />
                    <FormHelperText error={Boolean(destinationAddressError)}>
                      {t('ErrorCode.LOCATION_DIFFERENCE_TOO_BIG')}
                    </FormHelperText>
                  </>
                )}
            </>
          )}
          <PassengerCountField form={formInstance} />
          {overnight && (
            <FormControlGroup error={errors.driverAccommodationType} sx={{ mt: 2 }}>
              <ControlledField
                control={control}
                name='driverAccommodationType'
                render={(renderProps) => (
                  <DriverAccommodationField t={t} {...(renderProps.field as any)} />
                )}
              />
            </FormControlGroup>
          )}
          <ControlledField
            control={control}
            name='rides.0.legs.0.startTime'
            render={(renderProps) => (
              <DateTimePickerInputField
                {...renderProps}
                translationKey='startTime'
                fieldProps={{
                  disablePast: true,
                  minDateTime: newMinDate,
                  maxDateTime: newMaxDate,
                  format: getDateTimeLongFormatWithLocale(i18n.language),
                }}
              />
            )}
          />
          <FormHelperText error={Boolean(startTimeError)}>
            {t('ChangeOrderDetailsView.startDateHelperText')}
          </FormHelperText>

          {order.rides[0]?.legs[1]?.startTime && order.type !== RideOfferType.MULTI_STOP && (
            <>
              <ControlledField
                control={control}
                name='rides.0.legs.1.startTime'
                render={(renderProps) => (
                  <DateTimePickerInputField
                    {...renderProps}
                    translationKey='returnTime'
                    fieldProps={{
                      disablePast: true,
                      minDateTime: newMinDate,
                      maxDateTime: newMaxDate,
                      format: getDateTimeLongFormatWithLocale(i18n.language),
                    }}
                  />
                )}
              />
              <FormHelperText error={Boolean(returnTimeError)}>
                {t('ChangeOrderDetailsView.startDateHelperText')}
              </FormHelperText>
            </>
          )}
        </Box>
        <Box id='additionalInformationSection'>
          <EditOrderSectionTitle
            title={t('ChangeOrderDetailsView.steps.additionalDetails.title').toString()}
            subtitle={t('ChangeOrderDetailsView.steps.additionalDetails.subtitle').toString()}
          />
          <ControlledField
            control={control}
            name='pickUpLocationMessage'
            render={(renderProps) => (
              <TextareaInputField
                {...renderProps}
                fieldProps={{
                  minRows: 3,
                }}
              />
            )}
          />
          <ControlledField
            control={control}
            name='rideDetailsFiles'
            render={(renderProps) => (
              <InlineFileInputField
                type={FileTypeEnum.RIDE_DETAILS}
                {...renderProps}
                onFileRejected={handleRejectFile}
              />
            )}
          />
          <ControlledField control={control} name='signText' />
          <ControlledField
            control={control}
            name='signFiles'
            render={(renderProps) => (
              <InlineFileInputField
                type={FileTypeEnum.RIDE_SIGN}
                {...renderProps}
                onFileRejected={handleRejectFile}
              />
            )}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default GeneralOrderForm;
