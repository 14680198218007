import { Avatar, Grid, Typography } from '@mui/material';
import { colors, DriverDtoSchemaType } from 'husky-shared-fe-components';

interface Props {
  driverInfo: DriverDtoSchemaType;
}

function DriverInfo({ driverInfo }: Props) {
  return (
    <Grid container sx={{ p: '0 1rem 1rem 1rem' }} alignItems='center'>
      <Grid item xs='auto'>
        <Avatar
          sx={{
            marginRight: '10px',
            height: '49px',
            width: '49px',
            border: `2px solid ${colors.primary}`,
          }}
          alt='avatar'
          src={driverInfo?.driverPhotoUrl}
        />
      </Grid>
      <Grid item display='flex' alignItems='flex-start' flexDirection='column'>
        <Typography
          lineHeight='24px'
          fontSize='16px'
          fontWeight='500'
          sx={{ color: colors.gray1, marginLeft: '5px' }}
        >
          {driverInfo?.contactInfo.firstName} {driverInfo?.contactInfo.lastName}
        </Typography>
        <Typography
          lineHeight='24px'
          fontSize='16px'
          fontWeight='400'
          sx={{ color: colors.gray1, textDecoration: 'underline' }}
        >
          {driverInfo?.contactInfo.phone}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default DriverInfo;
