import { request } from 'api/requests';
import {
  AuthMutationResponse,
  CoordsParams,
  PasswordResetPayload,
  TokenStatusRequest,
  TokenStatusResponse,
  UserAccessDto,
} from 'api/types';
import { AxiosResponse } from 'axios';
import { LoginSchemaType, ResetPasswordTokenSchemaType } from 'components/forms/AccountForm/types';
import { FeedbackRequestSchemaType } from 'components/forms/ContactForm/types';
import { AcceptOperatorOfferRequestSchemaType } from 'components/forms/MakeOfferForm/types';
import { OfferClientDataSchemaType, OrderPublicDtoSchemaType } from 'components/forms/types';
import {
  NoOffersEmailInputSchemaType,
  OfferSchemaType,
  OrderDtoSchemaType,
  PickupSpot,
  StartPaymentRequestSchemaType,
  StartPaymentResponseSchemaType,
} from 'husky-shared-fe-components';
import Cookies from 'js-cookie';
import { EditOrderRequestSchemaType } from 'components/forms/OrderDriverForm/types';
import {
  ClientData,
  FileResponse,
  GoogleConfig,
  IdValueDtoLongString,
  ItineraryRequest,
  ItineraryResponse,
  OfferPayload,
  OffersSettings,
  OrderRequestPayload,
  PaymentStatus,
  VehiclePublic,
} from 'utils/data-types';
import { CookieKeyEnum } from 'utils/enums';

export const postAuthLogin = (
  data: LoginSchemaType
): Promise<AxiosResponse<AuthMutationResponse>> =>
  request({
    method: 'POST',
    url: `/auth/login`,
    data,
  });

export const postAuthRefresh = (
  potentialToken?: string | null | void
): Promise<AxiosResponse<AuthMutationResponse>> => {
  const refreshToken = potentialToken ?? Cookies.get(CookieKeyEnum.REFRESH_TOKEN_BUSINESS);
  return request({
    method: 'POST',
    url: '/auth/refresh',
    data: { refreshToken },
  });
};

export const fetchAuth = (): Promise<AxiosResponse<UserAccessDto>> =>
  request({
    url: `/users/current/accesses`,
  });

export const fetchMapsGoogleConfiguration = (): Promise<AxiosResponse<GoogleConfig>> =>
  request({
    url: `/maps/google/configuration`,
  });

export const fetchOffersSettings = (): Promise<AxiosResponse<OffersSettings>> =>
  request({
    url: `/offers/settings`,
  });

export const postOffers = (data: OfferPayload): Promise<AxiosResponse<OfferSchemaType>> =>
  request({
    method: 'POST',
    url: `/offers`,
    data,
  });

export const fetchOffersByBookingNumber = (
  bookingNumber: string
): Promise<AxiosResponse<OfferSchemaType>> =>
  request({
    url: `/offers/${bookingNumber}`,
  });

export const postAuthPasswordResetTokenStatus = (
  data: TokenStatusRequest
): Promise<AxiosResponse<TokenStatusResponse>> =>
  request({
    method: 'POST',
    url: `/auth/password-reset-token/status`,
    data,
  });

export const postAuthPasswordReset = (data: PasswordResetPayload): Promise<AxiosResponse<number>> =>
  request({
    method: 'POST',
    url: `/auth/password-reset`,
    data,
  });

export const postClientsOffers = (
  clientId: string,
  data: OfferPayload
): Promise<AxiosResponse<OfferSchemaType>> =>
  request({
    method: 'POST',
    url: `/clients/${clientId}/offers`,
    data,
  });

export const fetchOffersPaymentStatus = (
  bookingNumber: string
): Promise<AxiosResponse<PaymentStatus>> =>
  request({
    url: `/offers/${bookingNumber}/payment/status`,
  });

export const putOffersClientData = (
  bookingNumber: string,
  data: OfferClientDataSchemaType
): Promise<AxiosResponse<OfferSchemaType>> =>
  request({
    method: 'PUT',
    url: `/offers/${bookingNumber}/client-data`,
    data,
  });

export const postOffersPaymentStart = (
  bookingNumber: string,
  data: StartPaymentRequestSchemaType
): Promise<AxiosResponse<StartPaymentResponseSchemaType>> =>
  request({
    method: 'POST',
    url: `/offers/${bookingNumber}/payment/start`,
    data,
  });

export const postOrders = (
  data: OrderRequestPayload
): Promise<AxiosResponse<OrderPublicDtoSchemaType>> =>
  request({
    method: 'POST',
    url: `/orders`,
    data,
  });

export const fetchOrdersByBookingNumber = (
  bookingNumber: string
): Promise<AxiosResponse<OrderPublicDtoSchemaType>> =>
  request({
    url: `/orders/${bookingNumber}`,
  });

export const postClientsOrders = (
  clientId: string,
  data: OrderRequestPayload
): Promise<AxiosResponse<OrderPublicDtoSchemaType>> =>
  request({
    method: 'POST',
    url: `/clients/${clientId}/orders`,
    data,
  });

export const fetchOrdersFiles = (bookingNumber: string): Promise<AxiosResponse<FileResponse[]>> =>
  request({
    url: `/orders/${bookingNumber}/files`,
  });

export const deleteOrdersFilesByFileName = (
  bookingNumber: string,
  fileName: string
): Promise<AxiosResponse<number>> =>
  request({
    method: 'DELETE',
    url: `/orders/${bookingNumber}/files/${fileName}`,
  });

export const postOrdersFiles = (
  bookingNumber: string,
  data: FormData
): Promise<AxiosResponse<FileResponse>> =>
  request({
    method: 'POST',
    url: `/orders/${bookingNumber}/files`,
    headers: { 'Content-Type': 'multipart/form-data' },
    data,
  });

export const patchOrdersDetails = (
  bookingNumber: string,
  data: ClientData
): Promise<AxiosResponse<OrderPublicDtoSchemaType>> =>
  request({
    method: 'PATCH',
    url: `/orders/${bookingNumber}`,
    data,
  });

export const deleteOrderByBookingNumber = (bookingNumber: string): Promise<AxiosResponse<number>> =>
  request({
    method: 'DELETE',
    url: `/orders/${bookingNumber}`,
  });

export const fetchBlockedAreasPickUpSpots = (
  params: CoordsParams
): Promise<AxiosResponse<PickupSpot[]>> =>
  request({
    url: `/blocked-areas/pick-up-spots`,
    params: new URLSearchParams(params),
  });

export const postAuthPasswordResetToken = (
  data: ResetPasswordTokenSchemaType
): Promise<AxiosResponse<null>> =>
  request({
    method: 'POST',
    url: `/auth/password-reset-token`,
    data,
  });

export const postFeedback = (data: FeedbackRequestSchemaType): Promise<AxiosResponse<number>> =>
  request({
    method: 'POST',
    url: `/feedback`,
    data,
  });

export const postTripsItineraryCalculator = (
  data: ItineraryRequest
): Promise<AxiosResponse<ItineraryResponse>> =>
  request({
    method: 'POST',
    url: `/trips/itinerary-calculator`,
    data,
  });

export const postOffersAdditionalOffersEmail = (
  bookingNumber: string,
  data: NoOffersEmailInputSchemaType & { lang: string }
): Promise<AxiosResponse<number>> =>
  request({
    method: 'POST',
    url: `/offers/${bookingNumber}/additionaloffersemail`,
    data,
  });

export const fetchOperatorOffersByAccessToken = (
  accessToken: string
): Promise<AxiosResponse<OfferSchemaType>> =>
  request({
    url: `/operator-offers/${accessToken}`,
  });

export const fetchOrdersOperatorTokenByAccessToken = (
  accessToken: string
): Promise<AxiosResponse<OrderDtoSchemaType>> =>
  request({
    url: `/orders/operator-token/${accessToken}`,
  });

export const fetchOperatorOffersOperatorVehicles = (
  accessToken: string
): Promise<AxiosResponse<VehiclePublic[]>> =>
  request({
    url: `/operator-offers/${accessToken}/operator/vehicles`,
  });

export const postOperatorOffersApproval = (
  accessToken: string,
  data: AcceptOperatorOfferRequestSchemaType
): Promise<AxiosResponse<OfferSchemaType>> =>
  request({
    method: 'POST',
    url: `/operator-offers/${accessToken}/approval`,
    data,
  });

export const fetchOrdersOperatorTokenDriverCandidates = (
  accessToken: string
): Promise<AxiosResponse<IdValueDtoLongString[]>> =>
  request({
    url: `/orders/operator-token/${accessToken}/driver-candidates`,
  });

export const postOrdersOperatorToken = (
  accessToken: string,
  data: EditOrderRequestSchemaType
): Promise<AxiosResponse<OrderPublicDtoSchemaType>> =>
  request({
    method: 'POST',
    url: `/orders/operator-token/${accessToken}`,
    data,
  });
