import { driverDetailsSchema, zo } from 'husky-shared-fe-components';

const newDriverSchema = zo.object({
  contactInfo: driverDetailsSchema.shape.contactInfo.pick({
    firstName: true,
    lastName: true,
    email: true,
    phone: true,
  }),
});
type NewDriverSchemaType = zo.infer<typeof newDriverSchema>;

const editOrderRequestSchema = zo
  .object({
    newDriver: newDriverSchema.nullable(),
    driverId: zo.number().min(1).nullable(),
    pickExistingDriver: zo.boolean().optional(), // Only used internally to check boolean
  })
  .refine(
    (data) => {
      if (data.pickExistingDriver) {
        return data.driverId !== null;
      }
      return data.newDriver !== null;
    },
    { params: { i18n: 'required' }, path: ['driverId'] }
  );

type EditOrderRequestSchemaType = zo.infer<typeof editOrderRequestSchema>;

export { editOrderRequestSchema };
export type { EditOrderRequestSchemaType, NewDriverSchemaType };
