import { useCallback } from 'react';
import {
  colors,
  RideInfo,
  ControlledField,
  getRemainingTimeString,
  OfferSchemaType,
  formatDateTimeLongWithLocale,
  formatCurrency,
  OrderDtoSchemaType,
  DriverDtoSchemaType,
  RideDtoSchemaType,
} from 'husky-shared-fe-components';
import { v4 as uuidv4 } from 'uuid';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Paper, Box, Typography, Grid } from '@mui/material';
import { getOperatorOffer } from 'views/MakeOfferView';
import { EndAdornment } from '../form-helpers';
import DriverInfo from './DriverInfo';

function InfoTitle({ title }: { title: string }) {
  return (
    <div
      style={{
        fontWeight: 600,
        fontSize: '16px',
        color: colors.primary,
        marginBottom: '4px',
      }}
    >
      {title}
    </div>
  );
}

function InfoField({
  label,
  value,
  valueColor,
}: {
  label: string;
  value?: string;
  valueColor?: string;
}) {
  return (
    <div>
      <span
        style={{
          fontWeight: 400,
          color: colors.gray1,
          marginRight: '5px',
        }}
      >
        {label}
      </span>
      {!value ? null : (
        <span style={{ color: valueColor ?? colors.primary, fontWeight: 600 }}>{value}</span>
      )}
    </div>
  );
}

function Rides({ rides }: { rides: RideDtoSchemaType[] }) {
  const { t, i18n } = useTranslation();

  return (
    <Box>
      {rides.map((ride, index) => {
        return (
          <Box key={uuidv4()} sx={{ pr: 2, my: 1 }}>
            {rides.length > 1 && (
              <Typography
                sx={{
                  pl: '2rem',
                  pb: '0.5rem',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '20px',
                }}
              >
                {t('terms.attributes.ride.rideNo', { rideNo: index + 1 })}
              </Typography>
            )}
            <RideInfo language={i18n.language} selectedRide={ride as any} t={t} />
          </Box>
        );
      })}
    </Box>
  );
}

export function OrderInfo({ order }: { order: OrderDtoSchemaType }) {
  const { t, i18n } = useTranslation();

  // Find the ride by 0 index - Currently we do not have the ability to assign different driver and vehicle to separate rides
  const ride = order.rides[0];

  const getVehicles = useCallback(() => {
    if (ride == null) return null;

    const uniqueVehicles = [
      {
        label: `${ride.vehicle.make} ${ride.vehicle.model}`,
        value: ride.vehicle.id,
        vehicleClass: ride.vehicle.vehicleClass,
      },
    ];

    return uniqueVehicles.map(({ label, value, vehicleClass }, index) => {
      return (
        <Box key={value}>
          <Box
            sx={{
              fontWeight: 600,
              fontSize: '16px',
              color: colors.primary,
              textAlign: 'left',
            }}
          >
            {t('terms.carrierDetails.title', { index: uniqueVehicles.length > 1 ? index + 1 : '' })}
          </Box>
          <Box
            sx={{
              color: colors.gray2,
              fontWeight: 600,
              fontSize: '16px',
              marginTop: '12px',
              marginBottom: '4px',
            }}
          >
            {label}
          </Box>
          <Box
            sx={{
              fontStyle: 'italic',
              color: colors.primary,
              fontWeight: 500,
              display: 'flex',
              fontSize: '12px',
            }}
          >
            {vehicleClass}
          </Box>
        </Box>
      );
    });
  }, [ride, t]);

  return (
    <Paper
      elevation={3}
      sx={{
        flexDirection: 'column',
        display: 'flex',
        mb: '12px',
        borderRadius: '16px',
      }}
    >
      <Box
        sx={{
          p: '30px 25px',
        }}
      >
        {ride?.driver != null && <DriverInfo driverInfo={ride.driver as DriverDtoSchemaType} />}
        <InfoTitle title={t('MakeOfferView.clientDetails.overview')} />
        <Rides rides={order.rides} />
      </Box>
      <Box
        sx={{
          p: '30px 25px',
          borderTop: `1px solid ${colors.gray5}`,
          background: colors.tiffanyBlue5,
        }}
      >
        <InfoTitle title={t('terms.attributes.passengerCount')} />
        <InfoField
          label={`${t('terms.attributes.passengerCount')}:`}
          value={order?.passengerCount.toString() || '-'}
          valueColor={colors.linkBlack}
        />
        <InfoField
          label={t('terms.attributes.specialLuggage')}
          value={order?.specialLuggageCount?.toString() || '-'}
          valueColor={colors.linkBlack}
        />
        <Box mt='28px'>
          <InfoTitle title={t('terms.attributes.price.title')} />
          <Box display='flex' alignItems='center'>
            <InfoField label={t('terms.attributes.price.total.inclVat')} />
            <span
              style={{
                fontWeight: 700,
                color: colors.primary,
                fontSize: '20px',
              }}
            >
              {formatCurrency(order.operatorPriceInclVat, i18n.language)}
            </span>
          </Box>
        </Box>
      </Box>
      <Grid
        container
        style={{
          display: 'flex',
          padding: '16px 42px 32px',
          background: colors.tiffanyBlue5,
          alignItems: 'flex-start',
          borderTop: `1px solid ${colors.gray4}`,
        }}
      >
        <Grid item xs={12} sm={6} style={{ flex: 1, flexDirection: 'column', textAlign: 'left' }}>
          {getVehicles()}
        </Grid>
      </Grid>
    </Paper>
  );
}

interface Props {
  formInstance: UseFormReturn<any>;
  offerData: OfferSchemaType;
}
function NewOfferInfo({ offerData, formInstance }: Props) {
  const tInstance = useTranslation();
  const { control } = formInstance;
  const { t, i18n } = tInstance;

  // Select the first offer as default
  const { offer, trip } = getOperatorOffer(offerData);

  return (
    <Paper
      elevation={3}
      sx={{
        flexDirection: 'column',
        display: 'flex',
        mb: '12px',
        borderRadius: '16px',
      }}
    >
      <Box
        sx={{
          p: '30px 25px',
        }}
      >
        <InfoTitle title={t('MakeOfferView.clientDetails.overview')} />
        <Box m='16px 0'>
          {offer.clientMileageKm != null && (
            <InfoField
              label={t('terms.attributes.clientMileageKm')}
              value={t('terms.attributes.km', {
                count: Number(offer.clientMileageKm.toFixed(0)),
              }).toString()}
              valueColor={colors.tiffanyBlue1}
            />
          )}
          {offer.clientMileageKm != null && offer.mileageKm != null && (
            <InfoField
              label={t('terms.attributes.emptyRun')}
              value={t('terms.attributes.km', {
                count: Number((offer.mileageKm - offer.clientMileageKm).toFixed(0)),
              }).toString()}
              valueColor={colors.tiffanyBlue1}
            />
          )}
          {offer.clientHours != null && offer.clientDays != null && (
            <InfoField
              label={t('terms.attributes.travelTime')}
              value={getRemainingTimeString({
                clientHours: offer.clientHours,
                clientDays: offer.clientDays,
                t,
              })}
              valueColor={colors.tiffanyBlue1}
            />
          )}
        </Box>
        {/* TODO: Fix typing */}
        <Rides rides={trip.rides as any} />
      </Box>
      <Box
        sx={{
          p: '30px 25px',
          borderTop: `1px solid ${colors.gray5}`,
          background: colors.tiffanyBlue5,
        }}
      >
        <InfoTitle title={t('terms.attributes.passengerCount')} />
        <InfoField
          label={`${t('terms.attributes.passengerCount')}:`}
          value={offerData?.passengerCount.toString() || '-'}
          valueColor={colors.linkBlack}
        />
        <InfoField
          label={t('terms.attributes.specialLuggage')}
          value={offerData?.specialLuggageCount?.toString() || '-'}
          valueColor={colors.linkBlack}
        />
        <Box mt='28px'>
          <InfoTitle title={t('terms.attributes.price.title')} />
          <Box display='flex' alignItems='center' maxWidth='max-content'>
            <ControlledField
              control={control}
              name='newPriceExclVat'
              fieldProps={{
                InputProps: {
                  endAdornment: <EndAdornment text='EUR' />,
                  sx: { maxWidth: 125 },
                },
              }}
            />
          </Box>
        </Box>
        {offer.approvalDeadline != null && (
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 400,
              color: colors.restYellow,
              textAlign: 'center',
              mt: '28px',
            }}
          >
            <span>{t('OffersView.validUntil')} </span>
            <span style={{ fontWeight: 600 }}>
              {formatDateTimeLongWithLocale(offer.approvalDeadline, i18n.language)}
            </span>
          </Typography>
        )}
      </Box>
    </Paper>
  );
}

export default NewOfferInfo;
