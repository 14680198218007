import { ModalBase, colors } from 'husky-shared-fe-components';
import { Trans, useTranslation } from 'react-i18next';
import {
  COOKIE_CONSENT_FALSY,
  COOKIE_CONSENT_FALSY_EXPIRES,
  COOKIE_CONSENT_KEY,
  COOKIE_CONSENT_TRUTHY,
  COOKIE_CONSENT_TRUTHY_EXPIRES,
} from 'utils/enums';
import CookieIcon from '@mui/icons-material/Cookie';
import { grantFBQConsent, grantGTMConsent } from 'utils/cookie-helpers';
import { isFBQInitialized, isGTMInitialized } from 'utils/tracking';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { Button, Grid, Link, Typography } from '@mui/material';
import { ROUTES } from '@/utils/routes';
import useMenuItems from '@/utils/hooks/useMenuItems';

function SetupCookies() {
  const { t, i18n } = useTranslation();
  const [showCookieBanner, setShowCookieBanner] = useState(Cookies.get(COOKIE_CONSENT_KEY) == null);
  const [menuItems] = useMenuItems();
  const languageItem = menuItems.find((el) => el.itemKey === ROUTES.BaseRoutes.LANGUAGE);

  const handleAccept = () => {
    Cookies.set(COOKIE_CONSENT_KEY, COOKIE_CONSENT_TRUTHY, {
      expires: COOKIE_CONSENT_TRUTHY_EXPIRES, // Ask for cookie consent every year
    });

    if (isGTMInitialized) grantGTMConsent();
    if (isFBQInitialized) grantFBQConsent();

    setShowCookieBanner(false);
  };

  const handleReject = () => {
    Cookies.set(COOKIE_CONSENT_KEY, COOKIE_CONSENT_FALSY, {
      expires: COOKIE_CONSENT_FALSY_EXPIRES, // Ask for consent every week if customer declines
    });
    setShowCookieBanner(false);
  };

  return (
    <ModalBase
      open={showCookieBanner}
      title={t('CookieBanner.title').toString()}
      setOpen={(open) => {
        setShowCookieBanner(open);
        // This will be called when the user also presses the "Close" icon - in this case, we will accept the cookies by default
        if (!open) handleAccept();
      }}
      preventCloseOutside
      maxWidth='sm'
      {...(languageItem != null && {
        header: { children: languageItem.component },
      })}
    >
      <Grid container p={2} gap={3}>
        <Grid item xs={12}>
          <CookieIcon
            fontSize='large'
            sx={{
              display: 'flex',
              mx: 'auto',
              mb: 2,
              color: colors.purple1,
            }}
          />
          <Typography variant='body2' color={colors.headerBlack}>
            <Trans
              t={t}
              i18n={i18n}
              i18nKey='CookieBanner.subtitle'
              components={[
                <Link
                  key='0'
                  href={ROUTES.BaseRoutes.COOKIES}
                  sx={{
                    color: `${colors.primary}`,
                    fontWeight: 600,
                    textDecoration: 'underline',
                    fontSize: '14px',
                    '&:hover': { textDecoration: 'none' },
                  }}
                />,
              ]}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} display='flex' justifyContent='space-between' gap={2}>
          <Button variant='outlined' onClick={handleReject}>
            {t('reject')}
          </Button>
          <Button onClick={handleAccept}>{t('accept')}</Button>
        </Grid>
      </Grid>
    </ModalBase>
  );
}

export default SetupCookies;
