import { Box } from '@mui/material';
import lineartBg from 'assets/LineartBG.svg';
import lineartBg2 from 'assets/LineartBG2.svg';

function MapDisplay({ isContactPage = false }: { isContactPage?: boolean }) {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: 50,
        left: 0,
        bottom: 0,
        userSelect: 'none',
        pointerEvents: 'none',
      }}
    >
      <img
        src={isContactPage ? lineartBg2 : lineartBg}
        alt=''
        style={{
          width: '100%',
          height: '100%',
          objectPosition: 'bottom',
          objectFit: 'contain',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          zIndex: 51,
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'linear-gradient(180deg, rgba(243,248,249,1) 0%, rgba(243,248,249,0) 200%)',
          userSelect: 'none',
          pointerEvents: 'none',
        }}
      />
    </Box>
  );
}

export default MapDisplay;
