import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Button, useMediaQuery } from '@mui/material';
import { colors } from 'husky-shared-fe-components';
import { ROUTES } from 'utils/routes';
import { useTranslation } from 'react-i18next';

function ContactUsSection() {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        padding: smallScreen ? '16px' : 0,
      }}
    >
      <Box
        sx={{
          background: colors.gray13,
          border: `1px solid ${colors.tiffanyBlue3}`,
          borderRadius: '16px',
          width: '100%',
          maxWidth: '1216px',
          textAlign: 'center',
          marginBottom: '96px',
          padding: '32px',
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '30px',
            color: colors.tiffanyBlue,
            marginBottom: '32px',
          }}
        >
          {t('ContactView.cta.title')}
        </Typography>
        <Button
          onClick={() => {
            navigate(ROUTES.BaseRoutes.CONTACT);
          }}
        >
          {t('ContactView.cta.actions.contact')}
        </Button>
      </Box>
    </Box>
  );
}

export default ContactUsSection;
