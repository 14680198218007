import { useTranslation } from 'react-i18next';
import { colors } from 'husky-shared-fe-components';
import { Typography, Grid } from '@mui/material';
import { makeTranslationArray } from 'utils/helpers';

type BannerItem = { title: string; subtitle: string };

function BannerSectionLanding() {
  const tInstance = useTranslation();

  const items = makeTranslationArray<BannerItem>(
    'LandingView.hero.benefits',
    ['title', 'subtitle'],
    tInstance
  );

  return (
    <Grid
      container
      sx={{
        padding: ['98px 16px', null, '84px 48px'],
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Grid
        component='ol'
        container
        item
        maxWidth='xl'
        display='flex'
        justifyContent='center'
        spacing={[4, 8]}
        sx={{ p: 0, m: 0 }}
      >
        {Array.isArray(items) &&
          items.map((bannerSectionText) => (
            <Grid
              component='li'
              item
              lg={3}
              xs={12}
              key={bannerSectionText.title}
              sx={{ listStyle: 'none' }}
            >
              <Typography
                sx={{
                  fontSize: '48px',
                  color: colors.primary,
                  textAlign: 'center',
                  fontWeight: 700,
                  lineHeight: ['28px', '32px', '40px', '44px'],
                }}
              >
                {bannerSectionText.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  color: colors.gray1,
                  mt: '1rem',
                  lineHeight: '1.5rem',
                  textAlign: 'center',
                }}
              >
                {bannerSectionText.subtitle}
              </Typography>
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
}

export default BannerSectionLanding;
