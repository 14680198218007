import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PageLoaderFullScreen } from 'husky-shared-fe-components';
import { useAuthRefreshMutation } from 'api/queries/auth';
import { ROUTES } from 'utils/routes';

function RedirectView() {
  const navigate = useNavigate();
  const refreshMutation = useAuthRefreshMutation();
  const [searchParams] = useSearchParams();
  const refreshToken = searchParams.get('refresh');

  const { mutateAsync } = refreshMutation;
  useEffect(() => {
    (async () => {
      await mutateAsync(refreshToken);
      navigate(ROUTES.BaseRoutes.ROOT, { replace: true });
    })();
  }, [navigate, mutateAsync, refreshToken]);

  return <PageLoaderFullScreen />;
}

export default RedirectView;
