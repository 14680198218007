import { useMapsGoogleConfigurationQuery } from 'api/queries/config';
import { useUIMapsGoogleLoaded } from 'api/queries/ui';
import { useEffect } from 'react';

function SetupGoogleMaps() {
  const { data: googleConfig } = useMapsGoogleConfigurationQuery();
  const { setIsMapsLoaded } = useUIMapsGoogleLoaded();

  const { feApiKey } = googleConfig ?? {};
  useEffect(() => {
    if (feApiKey != null && feApiKey.length > 0) {
      if (window?.google?.maps) return;

      const scriptSrc = `https://maps.googleapis.com/maps/api/js?key=${feApiKey}&libraries=places&loading=async&callback=initMap`;
      const existingScript = document.querySelector(`script[src="${scriptSrc}"]`);

      if (existingScript) {
        return;
      }

      const apiScript = document.createElement('script');
      apiScript.src = scriptSrc;
      apiScript.async = true;
      document.body.appendChild(apiScript);
      // eslint-disable-next-line
      (window as any).initMap = function () {
        setIsMapsLoaded(true);
      };
    }
  }, [feApiKey, setIsMapsLoaded]);

  return null;
}

export default SetupGoogleMaps;
