import { useEffect } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useUIAdapterLocale } from 'api/queries/ui';

function SetupTranslations() {
  const { t, i18n, ready: i18nReady } = useTranslation();
  const { language: i18nLanguage, changeLanguage } = i18n;
  const { setAdapterLocale } = useUIAdapterLocale();

  // When the language changes, import the dayjs locale
  useEffect(() => {
    if (i18nReady) {
      const importDayJsLocale = (language: string) => {
        switch (language) {
          case 'et':
            return import('dayjs/locale/et');
          default:
            return import('dayjs/locale/en');
        }
      };

      (async () => {
        const loc = await importDayJsLocale(i18nLanguage);
        dayjs.locale(loc.name);
        setAdapterLocale(loc.name);
      })();
    }
  }, [i18nReady, i18nLanguage, t, setAdapterLocale]);

  // Listen to the url params and set the language to the specified 'lang'
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get('lang');
    if (!lang) return;

    if (i18nLanguage !== lang) changeLanguage(lang);
  }, [i18nLanguage, changeLanguage]);

  return null;
}

export default SetupTranslations;
