/* eslint-disable import/prefer-default-export */
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import { clearCookies, setCookies } from 'utils/cookie-helpers';
import { CookieKeyEnum } from 'utils/enums';
import { ROUTES } from 'utils/routes';
import { MobiferErrorResponse } from 'api/types';
import { postAuthRefresh } from './requests';

export const request = axios.create({
  baseURL: window.MOBIFER_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

request.interceptors.request.use((config) => {
  config.timeout = 10000;

  // We will force to use B2B token
  const jwtToken = Cookies.get(CookieKeyEnum.JWT_TOKEN_BUSINESS);
  if (jwtToken != null) config.headers.Authorization = `Bearer ${jwtToken}`;
  return config;
});

const resetAppState = () => {
  // Resets the cookies and makes sure that cookies are not persisted
  clearCookies();
  // We just redirect using the browser because we do not have access to hooks here
  // Only redirect when user is not on the login page
  if (!window.location.pathname.includes(ROUTES.BaseRoutes.LOGIN)) window.location.href = '/';
};

let isRetrying = false;
let queue: Function[] = []; // queue to requery all failed requests

request.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError<MobiferErrorResponse>) => {
    const { status } = error.response ?? {};
    const originalRequest: AxiosRequestConfig = error.config!;

    const isFailedStatus = status === 401;

    if (isFailedStatus) {
      if (!isRetrying) {
        isRetrying = true;
        try {
          const response = await postAuthRefresh();
          setCookies(response.data);
          isRetrying = false;

          // Retry all queued requests
          queue.forEach((cb) => cb(response.data.jwt));
          queue = []; // Clear the queue

          // Retry the original request with the new token
          originalRequest.headers!.Authorization = `Bearer ${response.data.jwt}`;
          return await request(originalRequest); // Await the request here
        } catch (refreshError) {
          // Reset app state if token refresh fails
          resetAppState();
          return Promise.reject(refreshError);
        }
      }

      // Log out the user if the request fails even after refresh
      if (isRetrying) return Promise.reject(error);

      // Push the request into the queue for retry
      return new Promise((resolve) => {
        queue.push((token: string) => {
          originalRequest.headers!.Authorization = `Bearer ${token}`;
          resolve(request(originalRequest));
        });
      });
    }

    // Log out the user if the request fails even after refresh
    if (isRetrying) resetAppState();
    return Promise.reject(error);
  }
);
