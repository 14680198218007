import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  AuthMutationResponse,
  MobiferErrorResponse,
  PasswordResetPayload,
  STALE_ONE_HOUR,
} from 'api/types';
import { AxiosError } from 'axios';
import { setCookies } from 'utils/cookie-helpers';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { LoginSchemaType, ResetPasswordTokenSchemaType } from 'components/forms/AccountForm/types';
import {
  postAuthLogin,
  postAuthPasswordReset,
  postAuthPasswordResetToken,
  postAuthPasswordResetTokenStatus,
  postAuthRefresh,
} from 'api/requests/requests';
import { CookieKeyEnum } from 'utils/enums';
import { UserData } from 'utils/data-types';
import { usersQueryKeys } from './users';

export const authQueryKeys = {
  all: ['auth'] as const,
  login: () => [...authQueryKeys.all, 'login'] as const,
  refresh: () => [...authQueryKeys.all, 'refresh'] as const,
  client: () => [...authQueryKeys.all, 'client'] as const,
  clientJwt: () => [...authQueryKeys.client(), 'jwt'] as const,
  resetToken: () => [...authQueryKeys.all, 'reset-token'] as const,
  passwordReset: () => [...authQueryKeys.all, 'password-reset'] as const,
  passwordResetToken: () => [...authQueryKeys.all, 'password-reset-token'] as const,
};

export function useAuthLoginMutation() {
  return useMutation<AuthMutationResponse, AxiosError<MobiferErrorResponse>, LoginSchemaType>({
    mutationKey: authQueryKeys.login(),
    mutationFn: async (v) => postAuthLogin(v).then((d) => d.data),
  });
}

export function useAuthRefreshMutation() {
  const queryClient = useQueryClient();

  return useMutation<AuthMutationResponse, AxiosError, string | null | void>({
    mutationKey: authQueryKeys.refresh(),
    mutationFn: (potentialToken) => postAuthRefresh(potentialToken).then((d) => d.data),
    onSuccess: async (data) => {
      await setCookies(data);
      queryClient.invalidateQueries({ queryKey: usersQueryKeys.currentAccesses() });
    },
  });
}

export function useAuthClientJwtQuery() {
  const jwtToken = Cookies.get(CookieKeyEnum.JWT_TOKEN_BUSINESS);

  return useQuery({
    queryKey: authQueryKeys.clientJwt(),
    queryFn: async () => {
      try {
        const decodedJwt: UserData = await jwtDecode(jwtToken!);
        return await Promise.resolve(decodedJwt);
      } catch (err) {
        return await Promise.reject(err);
      }
    },
    enabled: jwtToken != null, // jwtDecode should only be called when jwt is available
    // NOTE: How to set this query's staleTime as the decodedJwt's staleTime?
    staleTime: STALE_ONE_HOUR,
  });
}

export const useClientId = () => {
  const { data: userData } = useAuthClientJwtQuery();
  const clientId = userData?.['husky.clientId'];
  return clientId;
};

export function useAuthPasswordResetTokenStatusQuery(token: string) {
  return useQuery({
    queryKey: authQueryKeys.resetToken(),
    queryFn: async () => postAuthPasswordResetTokenStatus({ token }),
    enabled: token != null,
    select: ({ data }) => data,
  });
}

export function usePostAuthPasswordResetMutation() {
  return useMutation<number, AxiosError, PasswordResetPayload>({
    mutationKey: authQueryKeys.passwordReset(),
    mutationFn: (data) => postAuthPasswordReset(data).then((d) => d.status),
  });
}

export function useAuthPasswordResetTokenMutation() {
  return useMutation<number, AxiosError, ResetPasswordTokenSchemaType>({
    mutationKey: authQueryKeys.passwordResetToken(),
    mutationFn: (v) => postAuthPasswordResetToken(v).then((d) => d.status),
  });
}
