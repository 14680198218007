import { Box } from '@mui/system';
import { colors } from 'husky-shared-fe-components';
import { Typography } from '@mui/material';

function BannerSection({ text }: { text: string }) {
  return (
    <Box
      sx={{
        borderTop: `1px solid ${colors.primary}`,
        borderBottom: `1px solid ${colors.primary}`,
        py: ['1.5rem', '3rem'],
        mx: ['1rem', '3rem', '6rem', '8rem'],
        my: ['3rem', '3rem', '6rem'],
      }}
    >
      <Typography
        component='h3'
        sx={{
          fontSize: ['24px', '24px', '36px'],
          color: colors.tiffanyBlue,
          textAlign: 'center',
          fontWeight: 600,
          lineHeight: ['28px', '32px', '40px', '44px'],
          maxWidth: '1180px',
          mx: 'auto',
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}

export default BannerSection;
