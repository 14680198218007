import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import { Stack } from '@mui/system';
import {
  BankPaymentMethods,
  CheckboxInputField,
  ControlledField,
  FormControlGroup,
  TabButtons,
  PaymentBankOptionEnum,
  PaymentProviderEnum,
  PaymentTypeEnum,
  Offer,
  PaymentMethod,
} from 'husky-shared-fe-components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ClientPaymentActiveTabEnum } from 'utils/enums';
import {
  OrderPaymentByInvoiceSchemaType,
  OrderPaymentByProviderSchemaType,
  OrderPaymentSchemaType,
  orderPaymentByInvoiceSchema,
  orderPaymentByProviderSchema,
} from '../types';

function OrderPaymentByBankLinkForm({
  onValid,
  onBack,
  defaultValues,
}: {
  onValid: SubmitHandler<OrderPaymentByProviderSchemaType>;
  onBack: (data: OrderPaymentByProviderSchemaType) => void;
  defaultValues?: OrderPaymentByProviderSchemaType;
}) {
  const tInstance = useTranslation();
  const { t, i18n } = tInstance;
  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    trigger,
    formState: { isSubmitting, isValidating, isLoading, errors },
  } = useForm<OrderPaymentByProviderSchemaType>({
    defaultValues: {
      termsAccepted: undefined,
      lang: i18n.language,
      offerUuid: '',
      preferredBank: null,
      providerType: PaymentProviderEnum.MONTONIO,
      returnUrl: undefined,
      ...defaultValues,
      paymentType: PaymentTypeEnum.BANKLINK, // Do not overwrite paymentType
    },
    resolver: zodResolver(orderPaymentByProviderSchema),
  });
  const preferredBank = watch('preferredBank');

  return (
    <form onSubmit={handleSubmit(onValid)}>
      <FormControlGroup error={errors?.preferredBank as any} required>
        <BankPaymentMethods
          option={preferredBank}
          setOption={(value) => {
            setValue('preferredBank', value as PaymentBankOptionEnum);
            trigger('preferredBank');
          }}
          isError={Boolean(errors?.preferredBank)}
        />
      </FormControlGroup>
      <FormControlGroup error={errors?.termsAccepted} required>
        <ControlledField
          control={control}
          name='termsAccepted'
          render={(renderProps) => (
            <CheckboxInputField {...renderProps} translationKey='termsAcceptedOrder' />
          )}
        />
      </FormControlGroup>
      <Stack direction='row' justifyContent='space-between' spacing={1} my='2rem'>
        <Button
          type='button'
          onClick={() => onBack(getValues())}
          variant='outlined'
          disabled={isSubmitting || isValidating || isLoading}
        >
          {t('back')}
        </Button>
        <LoadingButton
          type='submit'
          disabled={isSubmitting || isValidating}
          loading={isLoading}
          variant='contained'
          sx={{ display: 'flex', maxWidth: 300 }}
        >
          {t('actions.user.confirmOrder')}
        </LoadingButton>
      </Stack>
    </form>
  );
}

function OrderPaymentByCardForm({
  onValid,
  onBack,
  defaultValues,
}: {
  onValid: SubmitHandler<OrderPaymentByProviderSchemaType>;
  onBack: (data: OrderPaymentByProviderSchemaType) => void;
  defaultValues?: OrderPaymentByProviderSchemaType;
}) {
  const tInstance = useTranslation();
  const { t, i18n } = tInstance;
  const {
    handleSubmit,
    control,
    getValues,
    formState: { isSubmitting, isValidating, isLoading, errors },
  } = useForm<OrderPaymentByProviderSchemaType>({
    defaultValues: {
      termsAccepted: undefined,
      lang: i18n.language,
      offerUuid: '',
      preferredBank: null,
      providerType: PaymentProviderEnum.MONTONIO,
      returnUrl: undefined,
      ...defaultValues,
      paymentType: PaymentTypeEnum.CARD_PAYMENT, // Do not overwrite paymentType
    },
    resolver: zodResolver(orderPaymentByProviderSchema),
  });

  return (
    <form onSubmit={handleSubmit(onValid)}>
      <FormControlGroup error={errors?.termsAccepted} required>
        <ControlledField
          control={control}
          name='termsAccepted'
          render={(renderProps) => (
            <CheckboxInputField {...renderProps} translationKey='termsAcceptedOrder' />
          )}
        />
      </FormControlGroup>
      <Stack direction='row' justifyContent='space-between' spacing={1} mt='2rem'>
        <Button
          type='button'
          onClick={() => onBack(getValues())}
          variant='outlined'
          disabled={isSubmitting || isValidating || isLoading}
        >
          {t('back')}
        </Button>
        <LoadingButton
          type='submit'
          disabled={isSubmitting || isValidating}
          loading={isLoading}
          variant='contained'
        >
          {t('actions.user.confirmOrder')}
        </LoadingButton>
      </Stack>
    </form>
  );
}

function OrderPaymentByInvoiceForm({
  onValid,
  onBack,
  defaultValues,
}: {
  onValid: SubmitHandler<OrderPaymentByInvoiceSchemaType>;
  onBack: (data: OrderPaymentByInvoiceSchemaType) => void;
  defaultValues?: OrderPaymentByInvoiceSchemaType;
}) {
  const tInstance = useTranslation();
  const { t } = tInstance;
  const {
    handleSubmit,
    control,
    getValues,
    formState: { isSubmitting, isValidating, isLoading, errors },
  } = useForm<OrderPaymentByInvoiceSchemaType>({
    defaultValues: {
      termsAccepted: undefined,
      ...defaultValues,
    },
    resolver: zodResolver(orderPaymentByInvoiceSchema),
  });

  return (
    <form onSubmit={handleSubmit(onValid)}>
      <FormControlGroup error={errors?.termsAccepted} required>
        <ControlledField
          control={control}
          name='termsAccepted'
          render={(renderProps) => (
            <CheckboxInputField {...renderProps} translationKey='termsAcceptedOrder' />
          )}
        />
      </FormControlGroup>
      <Stack direction='row' justifyContent='space-between' spacing={1} my='2rem'>
        <Button
          type='button'
          onClick={() => onBack(getValues())}
          variant='outlined'
          disabled={isSubmitting || isValidating || isLoading}
        >
          {t('back')}
        </Button>
        <LoadingButton
          type='submit'
          disabled={isSubmitting || isValidating}
          loading={isLoading}
          variant='contained'
          sx={{ display: 'flex', maxWidth: 300 }}
        >
          {t('actions.user.confirmOrder')}
        </LoadingButton>
      </Stack>
    </form>
  );
}

function OrderPaymentStepForm({
  onValid,
  onBack,
  defaultValues,
  offer,
  activeTab,
  setActiveTab,
}: {
  onValid: SubmitHandler<OrderPaymentSchemaType>;
  onBack: (data: OrderPaymentSchemaType) => void;
  defaultValues?: OrderPaymentSchemaType;
  offer: Offer;
  activeTab: ClientPaymentActiveTabEnum;
  setActiveTab: (tab: ClientPaymentActiveTabEnum) => void;
}) {
  const tInstance = useTranslation();
  const { t } = tInstance;
  const isPayLaterAvailable = offer.paymentMethods.includes(PaymentMethod.PAY_LATER);

  const tabs = [
    ClientPaymentActiveTabEnum.BY_BANK,
    ClientPaymentActiveTabEnum.BY_CARD,
    isPayLaterAvailable && ClientPaymentActiveTabEnum.BY_INVOICE,
  ].filter(Boolean) as ClientPaymentActiveTabEnum[];

  return (
    <Box textAlign='left'>
      <Box data-testid='payment-options'>
        <TabButtons
          tabs={tabs.map((k) => t(`PaymentOption.${k}`))}
          activeTab={tabs.indexOf(activeTab)}
          setActiveTab={(idx) => setActiveTab(tabs[idx])}
        />
      </Box>
      <Box sx={{ mb: '24px' }} />
      {activeTab === ClientPaymentActiveTabEnum.BY_BANK && (
        <OrderPaymentByBankLinkForm
          onValid={onValid}
          onBack={onBack}
          defaultValues={defaultValues as OrderPaymentByProviderSchemaType}
        />
      )}
      {activeTab === ClientPaymentActiveTabEnum.BY_CARD && (
        <OrderPaymentByCardForm
          onValid={onValid}
          onBack={onBack}
          defaultValues={defaultValues as OrderPaymentByProviderSchemaType}
        />
      )}
      {activeTab === ClientPaymentActiveTabEnum.BY_INVOICE && isPayLaterAvailable && (
        <OrderPaymentByInvoiceForm
          onValid={onValid}
          onBack={onBack}
          defaultValues={defaultValues as OrderPaymentByInvoiceSchemaType}
        />
      )}
    </Box>
  );
}

export default OrderPaymentStepForm;
