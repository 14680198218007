import { AxiosError } from 'axios';
import { UserPermission, UserRole } from 'husky-shared-fe-components';
import { TokenStatus } from 'utils/enums';

export interface MobiferErrorResponse {
  error: string;
  errorCode: string;
  path: string;
  status: number;
  timestamp: number;
}

declare module '@tanstack/react-query' {
  interface Register {
    defaultError: AxiosError<MobiferErrorResponse>;
  }
}

export const STALE_FIVE_MINUTES = 1000 * 60 * 5; // 5 minutes
export const STALE_ONE_HOUR = 1000 * 60 * 60; // 60 minutes

export type PaginationQueryParams = {
  pageNo?: string;
  pageSize?: string;
  search?: string;
};

export type UserAccessDto = {
  driverId?: number;
  email: string;
  operatorId: number;
  permissions: Record<string, UserPermission>;
  roles: UserRole[];
  userId: number;
};

export type AuthMutationResponse = {
  jwt: string;
  refreshToken: string;
};

export type TokenStatusRequest = {
  token: string;
};

export type TokenStatusResponse = {
  email?: string;
  role?: UserRole;
  status: TokenStatus;
};

export type PasswordResetPayload = {
  confirmPassword: string;
  password: string;
  token: string;
};

export type CoordsParams = {
  lat: string;
  lon: string;
};
