import { colors } from 'husky-shared-fe-components';
import { ListItem, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeTranslationArray } from 'utils/helpers';

function CheckIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none' viewBox='0 0 32 32'>
      <rect width='32' height='32' fill='#0697C7' fillOpacity='0.05' rx='16' />
      <path
        fill='#007EA7'
        fillRule='evenodd'
        d='M22.795 9.853l-9.546 9.214-2.534-2.707c-.466-.44-1.2-.467-1.733-.093-.52.386-.667 1.066-.347 1.613l3 4.88c.294.453.8.733 1.374.733a1.63 1.63 0 001.36-.733c.48-.627 9.64-11.547 9.64-11.547 1.2-1.226-.254-2.306-1.214-1.373v.013z'
        clipRule='evenodd'
      />
    </svg>
  );
}

type Item = { title: string };
function OperatorCTAListItems() {
  const tInstance = useTranslation();

  const items: Item[] = makeTranslationArray<Item>(
    'LandingView.operator.benefits',
    ['title'],
    tInstance
  );

  if (!Array.isArray(items)) return null;

  return (
    <>
      {items.map(({ title }) => (
        <ListItem key={title}>
          <CheckIcon />
          <ListItemText
            sx={{
              marginLeft: '16px',
              '& .MuiTypography-root': {
                color: colors.gray1,
                fontWeight: 400,
              },
            }}
          >
            {title}
          </ListItemText>
        </ListItem>
      ))}
    </>
  );
}

export default OperatorCTAListItems;
