import { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { track } from 'utils/tracking';
import { OperatorOfferSchemaType } from 'husky-shared-fe-components';
import { ROUTES } from 'utils/routes';
import { useOffersByBookingNumberQuery } from 'api/queries/offers';
import CreateOrderView from 'components/CreateOrder';

function ContinueBookingView() {
  const { bookingNumber, uuid } = useParams();
  const { data: offers, isSuccess, isFetched } = useOffersByBookingNumberQuery(bookingNumber);
  const [isViewCartTrackSet, setIsViewCartTrackSet] = useState(false);

  // As technically a user can view the cart multiple times, we run this
  useEffect(() => {
    if (offers != null && !isViewCartTrackSet) {
      const operatorOffers = offers.trips.flatMap((el) => el.operatorOffers);
      const product = operatorOffers.find((el) => el.uuid === uuid);
      track.view_cart(product as OperatorOfferSchemaType);
      setIsViewCartTrackSet(true);
    }
  }, [offers, isViewCartTrackSet, uuid]);

  if (isFetched && !isSuccess) {
    return <Navigate to={ROUTES.BaseRoutes.ROOT} replace />;
  }

  if (offers == null) return null;

  return <CreateOrderView />;
}

export default ContinueBookingView;
