import { useState } from 'react';
import {
  ControlledField,
  VehicleSelectField,
  CheckboxInputField,
  colors,
} from 'husky-shared-fe-components';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/system';
import { VehiclePublic } from 'utils/data-types';
import { PersonOutline } from '@mui/icons-material';
import { AcceptOperatorOfferRequestSchemaType, MakeOfferVehicleSchemaType } from './types';

interface Props {
  pickExistingVehicle?: boolean;
  vehicles: VehiclePublic[];
  handleSelectVehicle: (licensePlate: string) => void;
  formInstance: UseFormReturn<AcceptOperatorOfferRequestSchemaType>;
}

const newVehicle = {
  licensePlate: '',
  make: '',
  model: '',
  year: '' as unknown as number,
  seats: '' as unknown as number,
};

function NewVehicleDetailsFields({ vehicles, handleSelectVehicle, formInstance }: Props) {
  const tInstance = useTranslation();
  const { t } = tInstance;
  const { control, setValue, clearErrors, watch } = formInstance;
  const [prevNewVehicle, setPrevNewVehicle] = useState<MakeOfferVehicleSchemaType | null>(
    newVehicle
  );
  const [pickExistingVehicle, minPassengerCount, maxPassengerCount] = watch([
    'pickExistingVehicle',
    'minPassengerCount',
    'maxPassengerCount',
  ]);

  const [prevLicensePlate, setPrevLicensePlate] = useState<null | string>(null);
  const [prevVehicleId, setPrevVehicleId] = useState<number | null | undefined>();

  const newVehicleWatch = watch('newVehicle');
  const licensePlateWatch = watch('licensePlate');
  const vehicleIdWatch = watch('vehicleId');

  const handleToggleExistingVehicle = (isTruthy: boolean) => {
    setValue('pickExistingVehicle', isTruthy);

    if (isTruthy) {
      setValue('newVehicle', null);
      setValue('licensePlate', prevLicensePlate);
      setValue('vehicleId', prevVehicleId);
      setPrevNewVehicle(newVehicleWatch);
      clearErrors('newVehicle');
    } else {
      setValue('licensePlate', null);
      setValue('newVehicle', prevNewVehicle);
      setValue('vehicleId', null);
      setPrevLicensePlate(licensePlateWatch);
      setPrevVehicleId(vehicleIdWatch);
    }
  };

  const vehicleLicensePlates = vehicles.map(({ licensePlate }) => licensePlate);

  return (
    <Stack gap={1}>
      {vehicles.length > 0 && (
        <ControlledField
          control={control}
          name='pickExistingVehicle'
          render={(renderProps) => (
            <CheckboxInputField
              {...renderProps}
              fieldProps={{
                onChange: (e) => handleToggleExistingVehicle(e.target.checked),
              }}
              translationKey='pickExistingVehicle'
            />
          )}
        />
      )}
      {pickExistingVehicle && (
        <VehicleSelectField
          name='licensePlate'
          onChange={handleSelectVehicle}
          formInstance={formInstance}
          filteredVehicles={vehicleLicensePlates}
        />
      )}
      {!pickExistingVehicle && (
        <>
          <ControlledField
            control={control}
            name='newVehicle.licensePlate'
            translationKey='licensePlate'
          />
          <ControlledField control={control} name='newVehicle.make' translationKey='make' />
          <ControlledField control={control} translationKey='model' name='newVehicle.model' />
          <ControlledField control={control} name='newVehicle.year' translationKey='year' />
          <ControlledField
            control={control}
            name='newVehicle.seats'
            fieldProps={{
              helperText: t('FormFields.newVehicle.seats.helperText', {
                min: minPassengerCount,
                max: maxPassengerCount,
              }),
              InputProps: {
                endAdornment: (
                  <Box sx={{ userSelect: 'none', pointerEvents: 'none', lineHeight: '16px' }}>
                    <PersonOutline sx={{ color: colors.gray3 }} />
                  </Box>
                ),
              },
            }}
          />
        </>
      )}
    </Stack>
  );
}

export default NewVehicleDetailsFields;
