import { colors, ControlledField } from 'husky-shared-fe-components';
import { Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Stack } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { usePostAuthPasswordResetMutation } from 'api/queries/auth';
import { ROUTES } from 'utils/routes';
import { changePasswordSchema, ChangePasswordSchemaType } from './types';

function ChangePasswordForm() {
  const tInstance = useTranslation();
  const { t } = tInstance;
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isValidating, isLoading },
  } = useForm<ChangePasswordSchemaType>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: zodResolver(changePasswordSchema),
  });
  const postAuthPasswordResetMutation = usePostAuthPasswordResetMutation();

  const onValid = async (data: ChangePasswordSchemaType) => {
    await postAuthPasswordResetMutation.mutateAsync({ ...data, token: id! });
    toast.success(t('Toast.success'));
    navigate(ROUTES.BaseRoutes.LOGIN, { replace: true });
  };

  return (
    <form onSubmit={handleSubmit(onValid)} style={{ width: '100%', maxWidth: '350px' }}>
      <Typography sx={{ color: colors.primary, fontWeight: 600, fontSize: '30px', mb: 2 }}>
        {t('PasswordResetView.title')}
      </Typography>
      <Stack direction='column' spacing={1} textAlign='left'>
        <ControlledField
          control={control}
          name='password'
          focusInitial
          fieldProps={{
            InputProps: {
              type: 'password',
              autoComplete: 'password',
            },
          }}
        />
        <ControlledField
          control={control}
          name='confirmPassword'
          fieldProps={{
            InputProps: {
              type: 'password',
              autoComplete: 'new-password',
            },
          }}
        />
      </Stack>
      <Button
        sx={{ marginTop: '24px', marginBottom: '16px' }}
        fullWidth
        type='submit'
        variant='contained'
        color='primary'
        disableElevation
        disabled={isSubmitting || isValidating || isLoading}
      >
        {t('submit')}
      </Button>
    </form>
  );
}

export default ChangePasswordForm;
