import { useMutation } from '@tanstack/react-query';
import { postFeedback } from 'api/requests/requests';
import { AxiosError } from 'axios';
import { FeedbackRequestSchemaType } from 'components/forms/ContactForm/types';

export const feedbackQueryKeys = {
  all: ['feedback'] as const,
};

export function usePostFeedbackMutation() {
  return useMutation<number, AxiosError, FeedbackRequestSchemaType>({
    mutationKey: feedbackQueryKeys.all,
    mutationFn: (v) => postFeedback(v).then((d) => d.data),
  });
}
