import { Box, Typography } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';
import AppMenu from 'components/AppMenu';
import Footer from 'components/Footer';
import {
  useOperatorOffersQuery,
  useOperatorsOffersOperatorVehiclesQuery,
} from 'api/queries/operator-offers';
import MakeOfferForm from 'components/forms/MakeOfferForm/MakeOfferForm';
import { ROUTES } from 'utils/routes';
import {
  OfferApprovalStatusEnum,
  OfferSchemaType,
  PageLoader,
  colors,
} from 'husky-shared-fe-components';
import { CheckCircle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

// This function selects the operator offer based on what is the cheapest
export function getOperatorOffer(offerData: OfferSchemaType) {
  // Extract all operatorOffers from trips along with their parent trip
  const allOffers = offerData.trips.flatMap((trip) =>
    trip.operatorOffers.map((offer) => ({ trip, offer }))
  );

  // Find the offer with the lowest operatorPriceInclVat
  const lowestOffer = allOffers.reduce((lowest, current) =>
    lowest.offer.operatorPriceInclVat < current.offer.operatorPriceInclVat ? lowest : current
  );

  return {
    trip: lowestOffer.trip,
    offer: lowestOffer.offer,
  };
}

function getIsAnyOfferApproved(offerData: OfferSchemaType) {
  const statuses = offerData.trips.flatMap((t) =>
    t.operatorOffers.flatMap((o) => o.approvalStatus)
  );
  const desiredStatuses = [
    OfferApprovalStatusEnum.AUTO_APPROVED,
    OfferApprovalStatusEnum.OPERATOR_APPROVED,
  ];

  if (statuses.some((status) => desiredStatuses.includes(status))) return true;
  return false;
}

function MakeOfferView() {
  const { t } = useTranslation();
  const { token } = useParams();
  const {
    data,
    isError,
    isSuccess: isOffersSuccess,
    isLoading: isOffersLoading,
  } = useOperatorOffersQuery(token);
  const {
    data: vehicles = [],
    isSuccess: isVehiclesSuccess,
    isLoading: isVehiclesLoading,
  } = useOperatorsOffersOperatorVehiclesQuery(isOffersSuccess ? token : undefined); // Only make vehicles request when offers is available

  const isLoading = isOffersLoading || isVehiclesLoading;
  if (isError) return <Navigate to={ROUTES.BaseRoutes.ROOT} replace />;
  if (isLoading || !data) return null;

  const { offer } = getOperatorOffer(data);

  const isSuccess = isOffersSuccess && isVehiclesSuccess;
  const isOfferAlreadyApproved = getIsAnyOfferApproved(data);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <AppMenu />
      {isLoading && (
        <Box display='flex' flex={1} flexGrow={1}>
          <PageLoader />
        </Box>
      )}
      {isSuccess && offer != null && !isOfferAlreadyApproved ? (
        <MakeOfferForm
          offer={data}
          vehicles={vehicles}
          defaultValues={{
            contactEmail: offer.operatorContactEmail ?? '',
            newPriceExclVat: offer.operatorPriceExclVat,
            pickExistingVehicle: vehicles.length > 0,
            uuid: offer.uuid,
          }}
        />
      ) : (
        <Box display='flex' flex={1} justifyContent='center' alignItems='center' mt={3}>
          <CheckCircle sx={{ fill: colors.activeGreen, mr: 0.5 }} />
          <Typography sx={{ color: colors.primary }}>
            {t('MakeOfferView.completed.title')}
          </Typography>
        </Box>
      )}

      <Footer />
    </Box>
  );
}

export default MakeOfferView;
