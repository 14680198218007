import {
  DriverAccommodationType,
  clientDtoSchema,
  contactInfoDtoSchema,
  groupLeadSchema,
  offerDtoSchema,
  startPaymentRequestSchema,
  billingEntityDtoSchema,
  zo,
} from 'husky-shared-fe-components';

const billingEntityDiscriminatedUnionSchema = zo
  .discriminatedUnion('isBillingEntitySameAsDefault', [
    zo.object({
      isBillingEntitySameAsDefault: zo.literal(false), // Only used to track boolean internally
      billingEntity: billingEntityDtoSchema.or(zo.undefined()),
    }),
    zo.object({
      isBillingEntitySameAsDefault: zo.literal(true), // Only used to track boolean internally
    }),
  ]) // Makes sure to assign 'null' as the value if it does not exist as only 'null' will clear the DB value
  .transform((data) => {
    if (data.isBillingEntitySameAsDefault) return { ...data, billingEntity: null };
    return data;
  });

const privateClientContactInfoSchema = billingEntityDiscriminatedUnionSchema.and(
  zo.object({
    isEInvoiceEnabled: zo.boolean(), // Only used to track boolean internally,
    client: clientDtoSchema.pick({ type: true }).extend({
      contactInfo: contactInfoDtoSchema,
    }),
  })
);

const businessClientContactInfoSchema = billingEntityDiscriminatedUnionSchema.and(
  zo.object({
    isEInvoiceEnabled: zo.boolean(), // Only used to track boolean internally
    client: clientDtoSchema.pick({
      type: true,
      companyName: true,
      companyEmail: true,
      companyPhone: true,
      lang: true,
      address: true,
      regCode: true,
      contactInfo: true,
    }),
  })
);

const clientContactInfoSchema = privateClientContactInfoSchema.or(businessClientContactInfoSchema);

const orderAccommodationSchema = zo.object({
  driverAccommodationType: zo.string().min(1).pipe(zo.nativeEnum(DriverAccommodationType)),
});

const orderPaymentByProviderSchema = zo.intersection(
  startPaymentRequestSchema,
  zo.object({
    termsAccepted: zo.literal(true),
  })
);

const orderPaymentByInvoiceSchema = zo.object({
  termsAccepted: zo.literal(true),
});

const orderPaymentSchema = orderPaymentByProviderSchema.or(orderPaymentByInvoiceSchema);

const offerAdditionalDataDto = clientContactInfoSchema.and(
  offerDtoSchema.pick({
    groupLead: true,
    pickUpLocationMessage: true,
    driverAccommodationType: true,
  })
);

const orderGroupLeadSchema = groupLeadSchema.extend({
  clientIsGroupLead: zo.boolean(),
});
type OrderGroupLeadSchemaType = zo.infer<typeof orderGroupLeadSchema>;

type OrderPaymentByProviderSchemaType = zo.infer<typeof orderPaymentByProviderSchema>;
type OrderPaymentByInvoiceSchemaType = zo.infer<typeof orderPaymentByInvoiceSchema>;
type OrderPaymentSchemaType = zo.infer<typeof orderPaymentSchema>;
type ClientContactInfoSchemaType = zo.infer<typeof clientContactInfoSchema>;
type OrderAccommodationSchemaType = zo.infer<typeof orderAccommodationSchema>;

type OfferAdditionalDataDtoSchemaType = zo.infer<typeof offerAdditionalDataDto>;

type PrivateClientContactInfoSchemaType = zo.infer<typeof privateClientContactInfoSchema>;
type BusinessClientContactInfoSchemaType = zo.infer<typeof businessClientContactInfoSchema>;

export {
  orderGroupLeadSchema,
  orderPaymentByProviderSchema,
  orderPaymentByInvoiceSchema,
  orderPaymentSchema,
  orderAccommodationSchema,
  privateClientContactInfoSchema,
  businessClientContactInfoSchema,
};

export type {
  OrderGroupLeadSchemaType,
  OrderPaymentByProviderSchemaType,
  OrderPaymentByInvoiceSchemaType,
  OrderPaymentSchemaType,
  ClientContactInfoSchemaType,
  OrderAccommodationSchemaType,
  OfferAdditionalDataDtoSchemaType,
  PrivateClientContactInfoSchemaType,
  BusinessClientContactInfoSchemaType,
};
