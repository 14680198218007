import { colors } from 'husky-shared-fe-components';
import { Box, Typography } from '@mui/material';

interface Props {
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  sx?: any;
}

function EditOrderMainTitle({ title, subtitle, sx }: Props) {
  return (
    <Box
      sx={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto 2rem',
        gap: 2,
        maxWidth: '500px',
        ...sx,
      }}
    >
      {title && (
        <Typography
          sx={{
            color: colors.primary,
            fontWeight: 600,
            fontSize: '30px',
          }}
        >
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography
          sx={{
            fontSize: '16px',
            color: colors.gray1,
            lineHeight: '24px',
          }}
          variant='body2'
        >
          {subtitle}
        </Typography>
      )}
    </Box>
  );
}

export default EditOrderMainTitle;
