import {
  ConfirmModal,
  FileTypeEnum,
  PageLoader,
  colors,
  DEFAULT_MAX_PASSENGER_COUNT,
  DEFAULT_MIN_PASSENGER_COUNT,
  getDateTimeLongFormatWithLocale,
  isDate,
} from 'husky-shared-fe-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { differenceWith, isEqual, get } from 'lodash-es';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AxiosError } from 'axios';
import {
  useDeleteOrderMutation,
  useOrdersByBookingNumberQuery,
  useOrdersFilesQuery,
  usePutOrdersDetailsMutation,
  useDeleteOrdersFilesMutation,
  usePostOrdersFilesMutation,
} from 'api/queries/orders';
import { FileResponse } from 'utils/data-types';
import dayjs from 'dayjs';
import EditOrderMainTitle from 'components/editOrder/EditOrderMainTitle';
import { Box } from '@mui/system';
import { useForm, ErrorOption } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { runSequentially } from 'utils/helpers';
import { Button, Grid, Paper } from '@mui/material';
import GeneralOrderForm from 'components/forms/editOrder/GeneralOrderForm';
import GroupLeadLuggageOrderForm from 'components/forms/editOrder/GroupLeadLuggageOrderForm';
import OrderEditChangeTracker from 'components/OrderEditChangeTracker';
import toast from 'react-hot-toast';
import { MobiferErrorResponse } from 'api/types';
import {
  EditOrderCombinedSchemaType,
  editOrderCombinedSchema,
  OrderPublicDtoSchemaType,
} from 'components/forms/types';
import mobiferLogoElement1 from 'assets/mobifer-logo-element-1.svg';
import mobiferLogoElement2 from 'assets/mobifer-logo-element-2.svg';
import largeTrashCan from 'assets/large-trashcan.svg';
import AppMenu from 'components/AppMenu';

export const trackedChangesKeys = [
  'passengerCount',
  'driverAccommodationType',
  'rides[0]legs[0]startTime',
  'rides[0]legs[1]startTime',
  'rides[0]legs[0]startLocation.address',
  'rides[0]legs[1]startLocation.address',
];
type ExtendedErrorOption = ErrorOption & { errorKey: string; fieldKey: string };

function Container({
  children,
  variant = 'default',
}: {
  children: React.ReactNode;
  variant?: 'default' | 'cancel';
}) {
  return (
    <Paper
      sx={{
        padding: ['2rem 1rem', '2rem', '3rem', '5rem'],
        maxWidth: 1000,
        margin: '2rem 0',
        position: 'relative',
        overflow: 'hidden',
      }}
      elevation={3}
    >
      {variant === 'default' && (
        <>
          <Box
            component='img'
            src={mobiferLogoElement1}
            alt=''
            sx={{
              position: 'absolute',
              top: 0,
              right: -100,
              userSelect: 'none',
              pointerEvents: 'none',
            }}
          />
          <Box
            component='img'
            src={mobiferLogoElement2}
            alt=''
            sx={{
              position: 'absolute',
              bottom: 0,
              left: -25,
              userSelect: 'none',
              pointerEvents: 'none',
            }}
          />
        </>
      )}
      {variant === 'cancel' && (
        <Box
          component='img'
          src={largeTrashCan}
          alt=''
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            userSelect: 'none',
            pointerEvents: 'none',
          }}
        />
      )}
      {children}
    </Paper>
  );
}

function EditOrder({
  order,
  orderFiles,
  isOrderSuccess,
  isOrderLoading,
  orderError,
}: {
  order: OrderPublicDtoSchemaType;
  orderFiles: FileResponse[];
  isOrderSuccess: boolean;
  isOrderLoading: boolean;
  orderError: AxiosError<MobiferErrorResponse, any> | null;
}) {
  const tInstance = useTranslation();
  const { t, i18n } = tInstance;
  const { bookingNumber } = useParams();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('xl'));
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const deleteOrderMutation = useDeleteOrderMutation(bookingNumber!);
  const rides = order.rides ?? [];
  const maxPassengerCount = Math.max(...rides.map((r) => r.vehicle.seats));
  const origSignFiles = orderFiles?.filter((f) => f.fileType === FileTypeEnum.RIDE_SIGN);
  const origRideDetailsFiles = orderFiles?.filter((f) => f.fileType === FileTypeEnum.RIDE_DETAILS);
  const formInstance = useForm<EditOrderCombinedSchemaType>({
    defaultValues: {
      ...(order as any),
      maxPassengerCount: DEFAULT_MAX_PASSENGER_COUNT,
      signFiles: origSignFiles,
      rideDetailsFiles: origRideDetailsFiles,
      passengerCount: order.passengerCount.toString() ?? false,
      specialLuggage:
        typeof order.specialLuggageCount === 'number' && order.specialLuggageCount > 0,
      specialLuggageCount: null,
      specialLuggageType: null,
      pickUpLocationMessage: '',
    },
    resolver: zodResolver(editOrderCombinedSchema),
  });
  const {
    handleSubmit,
    reset,
    getValues,
    setError,
    watch,
    formState: { isSubmitting, isValidating, isLoading },
  } = formInstance;
  const [lastSubmittedValues, setLastSubmittedValues] = useState(getValues());
  const putOrdersDetailsMutation = usePutOrdersDetailsMutation(bookingNumber!);
  const deleteOrdersFiles = useDeleteOrdersFilesMutation(bookingNumber!);
  const postOrdersFiles = usePostOrdersFilesMutation(bookingNumber!);

  const focusErrorList: ExtendedErrorOption[] = [
    {
      errorKey: 'VEHICLE_DOESNT_HAVE_ENOUGH_SEATS',
      fieldKey: 'passengerCount',
      message: t(`FormFields.passengerCount.helperText`, {
        min: DEFAULT_MIN_PASSENGER_COUNT,
        max: maxPassengerCount,
      }) as string,
    },
  ];

  const onValid = async (data: EditOrderCombinedSchemaType) => {
    const { signFiles, rideDetailsFiles, ...rest } = data;
    const payload: EditOrderCombinedSchemaType = rest;

    try {
      const defaultFiles = [...(origSignFiles ?? []), ...(origRideDetailsFiles ?? [])];
      const files = [...(signFiles ?? []), ...(rideDetailsFiles ?? [])];
      // A file is uploaded if there is 'originalFile' attached
      const uploadedFiles = files.filter((f) => (f as any).originalFile != null);
      const removedFiles = differenceWith(defaultFiles, files, isEqual);
      const removedPromises = removedFiles.map(
        ({ fileName }) =>
          () =>
            deleteOrdersFiles.mutateAsync({ fileName })
      );
      const insertedPromises = uploadedFiles.map((file) => () => {
        const formData = new FormData();
        formData.append('file', (file as any).originalFile as File);
        formData.append('fileType', file.fileType);
        return postOrdersFiles.mutateAsync(formData);
      });

      // Call the promise functions sequentially
      await runSequentially(removedPromises);
      await runSequentially(insertedPromises);

      // Run put order last as it refetches the order also with correct data
      await putOrdersDetailsMutation.mutateAsync(payload as any);
      setLastSubmittedValues(payload);
      toast.success(t('Toast.success'));
    } catch (err: any) {
      const { errorCode } = err.response.data;
      const item = focusErrorList.find((e) => e.errorKey === errorCode);
      if (item) {
        setError(
          item.fieldKey as keyof EditOrderCombinedSchemaType,
          {
            type: errorCode,
            message: item.message || '',
          },
          { shouldFocus: true }
        );
      }
      // TODO: Capture sentry error
    }
  };

  const handleCancelBooking = async () => {
    await deleteOrderMutation.mutateAsync();
  };

  if (isOrderLoading) {
    return (
      <>
        <AppMenu />
        <Box sx={{ padding: '0 1rem' }}>
          <Container>
            <PageLoader />
          </Container>
        </Box>
      </>
    );
  }

  const isOrderCancelled =
    orderError?.response?.data?.errorCode === 'CANNOT_RETRIEVE_CANCELLED_ORDER';
  if (isOrderCancelled) {
    return (
      <>
        <AppMenu />
        <Box sx={{ padding: '0 1rem' }}>
          <Container>
            <EditOrderMainTitle title={t('OrderView.cancel.orderCancelled').toString()} />
            <Button
              component={Link}
              variant='text'
              to='/'
              sx={{
                fontSize: '14px',
                color: colors.primary,
                fontWeight: '500',
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
                maxWidth: 'max-content',
                margin: '0 auto',
                display: 'flex',
              }}
            >
              {t('actions.fetch.goHome')}
            </Button>
          </Container>
        </Box>
      </>
    );
  }

  if (!isOrderSuccess) return null;

  const currentValues = watch();
  const changedFields = trackedChangesKeys.reduce((acc: Record<string, any>, key) => {
    if (smallScreen) return acc;
    if (!isEqual(get(order, key)?.toString(), get(currentValues, key)?.toString())) {
      const isDateString = isDate(get(currentValues, key));

      if (isDateString) {
        const format = getDateTimeLongFormatWithLocale(i18n.language);
        const originalDate = dayjs(get(order, key) as string).format(format);
        const newDate = dayjs(get(currentValues, key) as string).format(format);
        if (originalDate === newDate) return acc;

        acc[key] = {
          original: originalDate,
          current: newDate,
        };
      } else {
        acc[key] = {
          original: get(order, key).toString(),
          current: get(currentValues, key).toString(),
        };
      }
    }
    return acc;
  }, {});
  const hasChanges = Object.keys(changedFields).length > 0;

  return (
    <form id='orderEditContainer' onSubmit={handleSubmit(onValid)}>
      <AppMenu />
      <Box
        sx={{
          padding: '0 1rem',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginLeft: hasChanges && !smallScreen ? '-150px' : 0,
        }}
      >
        <Container>
          <EditOrderMainTitle
            title={t('ChangeOrderDetailsView.title') as string}
            subtitle={t('ChangeOrderDetailsView.subtitle').toString()}
          />
          <GeneralOrderForm order={order} formInstance={formInstance} />
          {!hasChanges || smallScreen ? null : (
            <OrderEditChangeTracker order={order} trackedChanges={changedFields} />
          )}
        </Container>
        <Container>
          <EditOrderMainTitle
            title={t('ChangeOrderDetailsView.steps.extraDetails.title') as string}
            subtitle={t('ChangeOrderDetailsView.steps.extraDetails.subtitle') as string}
          />
          <GroupLeadLuggageOrderForm formInstance={formInstance} />
        </Container>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            py: '3rem',
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
          }}
        >
          <Button
            onClick={() => reset(lastSubmittedValues)}
            variant='outlined'
            sx={{
              minWidth: '155px',
              mr: {
                xs: '0',
                sm: '1rem',
              },
              mb: {
                xs: '1rem',
                sm: '0',
              },
            }}
          >
            {t('actions.user.discardChanges')}
          </Button>
          <Button
            type='submit'
            disabled={isSubmitting || isValidating || isLoading}
            variant='contained'
            sx={{ minWidth: '155px' }}
          >
            {t('save')}
          </Button>
        </Box>
        <Container variant='cancel'>
          <Grid container>
            <Grid item xs={12} md={4}>
              <EditOrderMainTitle
                sx={{
                  textAlign: {
                    xs: 'center',
                    md: 'left',
                  },
                  alignItems: {
                    xs: 'center',
                    md: 'flex-start',
                  },
                }}
                title={t('OrderView.cancel.title').toString()}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <EditOrderMainTitle
                sx={{
                  textAlign: {
                    xs: 'center',
                    md: 'left',
                  },
                  m: { xs: '0 auto 2rem', md: '0 0 2rem' },
                  whiteSpace: 'pre-line',
                  maxWidth: 'none',
                }}
                subtitle={t('OrderView.cancel.subtitle').toString()}
              />
              <Button
                onClick={() => setIsCancelModalOpen(true)}
                sx={{
                  margin: {
                    xs: '0 auto',
                    md: '0',
                  },
                  display: 'flex',
                  backgroundColor: colors.busyRed,
                  '&:hover, &:focus': { backgroundColor: `${colors.busyRed}!important` },
                }}
              >
                {t('OrderView.cancel.actions.cancelOrder')}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <ConfirmModal
          open={isCancelModalOpen}
          setOpen={setIsCancelModalOpen}
          onClose={() => setIsCancelModalOpen(false)}
          onConfirm={() => {
            if (!deleteOrderMutation.isPending) handleCancelBooking();
          }}
          title={t('OrderView.cancel.title').toString()}
          description={t('OrderView.cancel.subtitle').toString()}
        />
      </Box>
    </form>
  );
}

function EditOrderView() {
  const { bookingNumber } = useParams();
  const {
    data: order,
    isSuccess: isOrderSuccess,
    isLoading: isOrderLoading,
    error: orderError,
  } = useOrdersByBookingNumberQuery(bookingNumber);
  const { data: orderFiles } = useOrdersFilesQuery(bookingNumber);

  if (!order || !orderFiles) return null;

  return (
    <EditOrder
      order={{ ...order }}
      orderFiles={orderFiles}
      isOrderSuccess={isOrderSuccess}
      isOrderLoading={isOrderLoading}
      orderError={orderError}
    />
  );
}

export default EditOrderView;
