import { Box } from '@mui/material';
import MapDisplay from 'components/MapDisplay';
import CreateRideOffers from 'components/CreateRideOffers';
import AppMenu from 'components/AppMenu';
import Footer from 'components/Footer';
import BannerSectionLanding from 'components/landing/BannerSectionLanding';
import AboutSection from 'components/landing/AboutSection';
import CTASection from 'components/landing/CTASection';

function IndexView() {
  return (
    <>
      <AppMenu />
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          minHeight: ['auto', undefined, 'calc(100vh - 276px)'],
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
      >
        <MapDisplay />
        <Box
          sx={{
            maxWidth: '100%',
            position: 'relative',
            zIndex: 55,
            display: 'flex',
            pt: { xs: '2rem', md: '6rem' },
            px: '1rem',
            justifyContent: 'center',
          }}
        >
          <CreateRideOffers />
        </Box>
      </Box>
      <Box>
        <BannerSectionLanding />
        <AboutSection />
        <CTASection />
      </Box>
      <Footer />
    </>
  );
}

export default IndexView;
