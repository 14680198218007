import { useQuery } from '@tanstack/react-query';
import { fetchMapsGoogleConfiguration, fetchOffersSettings } from 'api/requests/requests';
import { STALE_ONE_HOUR } from 'api/types';

export const configQueryKeys = {
  all: ['config'] as const,
  offers: () => [...configQueryKeys.all, 'offers'] as const,
  google: () => [...configQueryKeys.all, 'google'] as const,
};

export function useOffersSettingsQuery() {
  return useQuery({
    queryKey: configQueryKeys.offers(),
    queryFn: fetchOffersSettings,
    staleTime: STALE_ONE_HOUR,
    select: ({ data }) => data,
  });
}

export function useMapsGoogleConfigurationQuery() {
  return useQuery({
    queryKey: configQueryKeys.google(),
    queryFn: fetchMapsGoogleConfiguration,
    staleTime: STALE_ONE_HOUR,
    select: ({ data }) => data,
  });
}
