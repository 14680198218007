import { useMutation } from '@tanstack/react-query';
import { postTripsItineraryCalculator } from 'api/requests/requests';
import { AxiosError } from 'axios';
import { ItineraryRequest, ItineraryResponse } from 'utils/data-types';

export const tripsQueryKeys = {
  all: ['trips'] as const,
  itineraryCalculator: () => [...tripsQueryKeys.all, 'itinerary-calculator'] as const,
};

export function useTripsItineraryCalculatorMutation() {
  return useMutation<ItineraryResponse, AxiosError, ItineraryRequest>({
    mutationKey: tripsQueryKeys.itineraryCalculator(),
    mutationFn: (v) => postTripsItineraryCalculator(v).then((d) => d.data),
  });
}
