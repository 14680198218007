import { colors } from 'husky-shared-fe-components';
import { Box, Typography } from '@mui/material';

interface Props {
  title?: string;
  subtitle?: string;
}

function EditOrderSectionTitle({ title, subtitle }: Props) {
  return (
    <Box sx={{ margin: '34px 0 24px', display: 'flex', flexDirection: 'column', gap: 2 }}>
      {title && <Typography sx={{ fontWeight: 500 }}>{title}</Typography>}
      {subtitle && (
        <Typography
          sx={{
            fontSize: '16px',
            color: colors.gray1,
            textAlign: 'left',
          }}
          variant='body2'
        >
          {subtitle}
        </Typography>
      )}
    </Box>
  );
}

export default EditOrderSectionTitle;
